import { createContext, useContext } from 'react';
import BwhsApiClient from '../services/bwhsApiClient';
import { ReactNode } from 'react';

const BwhsApiContext = createContext<BwhsApiClient | null>(null);


export default function BwhsApiProvider({ children }: { children: ReactNode }) {
  const api = new BwhsApiClient();

  return (
    <BwhsApiContext.Provider value= { api } >
    { children }
    </BwhsApiContext.Provider>
  );
}

export function useBwhsApi() {
  const bwhsApiContext = useContext(BwhsApiContext);
  if (!bwhsApiContext) {
    throw new Error('useBwhsApi must be used within a BwhsApiProvider');
  }
  return bwhsApiContext;
}
