import { Link } from "react-router-dom";
import Logo from "../../assets/images/bway-headshots-logo.png";
import WhiteLogo from "../../assets/images/bway-headshots-white-logo.svg";
import Banner from "../../assets/images/home-banner.jpg";
import Outlines from "../../assets/images/bg-outlines.png";
import ChooseLayout from "../../assets/images/layout.png";
import UploadPhoto from "../../assets/images/upload-photo.png";
import AddName from "../../assets/images/add-name.png";
import SendPrint from "../../assets/images/send-print.png";
import HowItWorkOutline from "../../assets/images/outline-2.png";
import HeadshotPrints from "../../assets/images/Headshot-Print.png";
import DigitalHeadshot from "../../assets/images/Headshot-Digital.png";
import YourDesign from "../../assets/images/Your-Design.png";
import Resume from "../../assets/images/Resume-Print.png";
import FooterWomanBg from "../../assets/images/footer-woman-bg.png";
import FooterOutline from "../../assets/images/footer-outline.png";

const homeRedirectUrl = process.env.REACT_APP_HOME_REDIRECT_URL;

const Home = () => {
  if (homeRedirectUrl && homeRedirectUrl.length > 0) {
    window.location.href = homeRedirectUrl;
    return null;
  }

  const handleClickForFeaturedSection = (event: React.MouseEvent) => {
    event.preventDefault();
    const featuredSection = document.getElementById("featured-section");
    if (featuredSection) featuredSection.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      {/* Main Banner Section */}
      <section
        className="relative bg-contain bg-center bg-no-repeat pt-6 pb-11 before:absolute before:-z-30 before:top-0 before:left-0 before:w-full before:h-full before:bg-gradient-to-r before:from-black-500 before:to-black-500"
        style={{ backgroundImage: `url(${Banner})` }}
      >
        <span
          className="absolute left-0 top-0 bg-no-repeat inset-0"
          style={{ backgroundImage: `url(${Outlines})` }}
        ></span>
        <div className="container">
          <div className="logo relative z-10">
            <img src={WhiteLogo} alt="BWAY HEADSHOTS" />
          </div>

          <div className="max-w-screen-sm pt-[172px] pb-[92px] relative z-10">
            <h1 className="text-[52px] text-white leading-[77px] font-normal font-['Rakkas']">
              Prints as Unique as You Are: Your Premium Headshot Story
            </h1>
          </div>

          <div className="relative flex flex-wrap items-center justify-center gap-4 max-w-[578px] mx-auto px-4 py-7 rounded-full border-gradient border border-transparent [&_a]:z-20">
            <Link
              to="/designer/prints"
              className="flex items-center justify-center h-[50px] text-base text-black-200 font-normal bg-white rounded-lg px-3 py-1 min-w-[190px] transition ease-in hover:bg-beige-100"
            >
              Print Headshot
            </Link>
            <a
              href="#featured-section"
              onClick={handleClickForFeaturedSection}
              className="flex items-center justify-center h-[50px] text-base text-white font-normal border border-white rounded-lg px-3 py-1 min-w-[190px] transition ease-in hover:bg-beige-100 hover:text-black-200"
            >
              Featured Products
            </a>
          </div>
        </div>
      </section>{" "}
      {/* End - Main Banner Section */}
      {/* How It Works Section */}
      <section className="relative bg-beige-200 pt-16 pb-[72px]">
        <div className="container relative z-10">
          <h2 className="text-[30px] leading-[40px] text-black-200 font-semibold uppercase text-center mb-10">
            How it Works
          </h2>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 text-center">
            <div className="bg-white rounded-lg px-5 py-8 [&>span]:w-[134px] [&>span]:h-[134px] [&>span]:rounded-full [&>span]:bg-beige-100 [&>span]:mx-auto [&>span]:mb-4">
              <span className="flex items-center justify-center">
                <img src={ChooseLayout} alt="Choose your Layout" />
              </span>
              <h4 className="text-base text-black-200 font-semibold mb-2 leading-none">
                Choose your Layout
              </h4>
              <p className="text-sm text-black-200 font-normal">
                Select the print orientation
                <br /> landscape or portrait
              </p>
            </div>

            <div className="bg-white rounded-lg px-5 py-8 [&>span]:w-[134px] [&>span]:h-[134px] [&>span]:rounded-full [&>span]:bg-beige-100 [&>span]:mx-auto [&>span]:mb-4">
              <span className="flex items-center justify-center">
                <img src={UploadPhoto} alt="Upload" />
              </span>
              <h4 className="text-base text-black-200 font-semibold mb-2 leading-none">
                Upload your Photo
              </h4>
              <p className="text-sm text-black-200 font-normal">
                We recommend uploading a high-quality JPEG image for optimal
                results.
              </p>
            </div>

            <div className="bg-white rounded-lg px-5 py-8 [&>span]:w-[134px] [&>span]:h-[134px] [&>span]:rounded-full [&>span]:bg-beige-100 [&>span]:mx-auto [&>span]:mb-4">
              <span className="flex items-center justify-center">
                <img src={AddName} alt="Add your Name" />
              </span>
              <h4 className="text-base text-black-200 font-semibold mb-2 leading-none">
                Add your Name
              </h4>
              <p className="text-sm text-black-200 font-normal">
                Adding names to headshots is vital for professional
                identification and communication
              </p>
            </div>

            <div className="bg-white rounded-lg px-5 py-8 [&>span]:w-[134px] [&>span]:h-[134px] [&>span]:rounded-full [&>span]:bg-beige-100 [&>span]:mx-auto [&>span]:mb-4">
              <span className="flex items-center justify-center">
                <img src={SendPrint} alt="Send to Print" />
              </span>
              <h4 className="text-base text-black-200 font-semibold mb-2 leading-none">
                Send to Print
              </h4>
              <p className="text-sm text-black-200 font-normal">
                We will take care the rest{" "}
              </p>
            </div>
          </div>
        </div>
        <span
          className="absolute right-0 bottom-0 bg-auto bg-right bg-no-repeat inset-1"
          style={{ backgroundImage: `url(${HowItWorkOutline})` }}
        ></span>
      </section>{" "}
      {/* End - How It Works Section */}
      {/* Featured Products */}
      <section
        id="featured-section"
        className="relative bg-black-400 pt-16 pb-[72px]"
      >
        <div className="container relative z-10">
          <h2 className="text-[30px] leading-[40px] text-white font-semibold uppercase text-center mb-10">
            Featured Products
          </h2>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 text-center">
            <div>
              <img
                src={HeadshotPrints}
                alt="Send to Print"
                className="w-full"
              />

              <div className="bg-black-300 px-6 py-8">
                <h4 className="text-3xl text-white font-semibold leading-none mb-5">
                  Headshot Prints
                </h4>

                <p className="text-base text-white font-normal mb-6">
                  Upload your photo and create the perfect 8"x10" print.
                </p>

                <Link
                  to="/designer/prints"
                  className="flex items-center justify-center h-[50px] text-base text-black-200 font-normal bg-white rounded-lg px-3 py-1 w-full transition ease-in hover:bg-beige-100"
                >
                  Print Headshot
                </Link>
              </div>
            </div>

            <div>
              <img
                src={DigitalHeadshot}
                alt="Send to Print"
                className="w-full"
              />

              <div className="bg-black-300 px-6 py-8">
                <h4 className="text-3xl text-white font-semibold leading-none mb-5">
                  Digital Headshot
                </h4>

                <p className="text-base text-white font-normal mb-6">
                  Create the perfect format for digital format only.
                </p>

                <Link
                  to="/designer/digital"
                  className="flex items-center justify-center h-[50px] text-base text-black-200 font-normal bg-white rounded-lg px-3 py-1 w-full transition ease-in hover:bg-beige-100"
                >
                  Get Digital Copy
                </Link>
              </div>
            </div>

            <div>
              <img src={YourDesign} alt="Send to Print" className="w-full" />

              <div className="bg-black-300 px-6 py-8">
                <h4 className="text-3xl text-white font-semibold leading-none mb-5">
                  Your Design
                </h4>

                <p className="text-base text-white font-normal mb-6">
                  Upload your already-formatted layout/design for us to print.
                </p>

                <Link
                  to="/designer/design"
                  className="flex items-center justify-center h-[50px] text-base text-black-200 font-normal bg-white rounded-lg px-3 py-1 w-full transition ease-in hover:bg-beige-100"
                >
                  Print Design
                </Link>
              </div>
            </div>

            <div>
              <img src={Resume} alt="Send to Print" className="w-full" />

              <div className="bg-black-300 px-6 py-8">
                <h4 className="text-3xl text-white font-semibold leading-none mb-5">
                  Resume 8x10
                </h4>

                <p className="text-base text-white font-normal mb-6">
                  Upload your resume and we will print it on 8"x10" paper.
                </p>

                <Link
                  to="/designer/resume"
                  className="flex items-center justify-center h-[50px] text-base text-black-200 font-normal bg-white rounded-lg px-3 py-1 w-full transition ease-in hover:bg-beige-100"
                >
                  Print Resume
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
      {/* End - Featured Products */}
      {/* Footer Section */}
      <footer className="relative overflow-hidden bg-beige-500 py-12">
        <span
          className="hidden md:block absolute left-0 top-0 bg-auto bg-no-repeat bg-[left_bottom] inset-0"
          style={{ backgroundImage: `url(${FooterWomanBg})` }}
        ></span>
        <div className="container relative z-20 space-y-10">
          <h2 className="text-3xl text-black-200 font-semibold text-center uppercase leading-10">
            Contact your Print Shop
          </h2>

          <img src={Logo} alt="BWAY HEADSHOTS" className="mx-auto" />

          <div className="text-center [&_p]:text-black-200 [&_p]:uppercase">
            <p className="text-lg font-medium mb-2">
              For questions about an order
            </p>
            <p className="text-2xl font-bold">
              Call : <a href="tel:646-661-7114">646-661-7114</a>
            </p>
          </div>

          <p className="text-lg text-black-200 text-center font-medium uppercase">
            Customer Service <br />
            1601 Broadway, 11th Floor
            <br />
            New York, NY 10019
            <br />
            United States
          </p>

          <p className="text-sm text-black-200 text-center">
            Version: {process.env.REACT_APP_VERSION}{" "}
            {process.env.REACT_APP_VERSION_ENV_SUFFIX}
          </p>
        </div>
        <span
          className="absolute left-0 top-0 bg-contain bg-right bg-no-repeat inset-0"
          style={{ backgroundImage: `url(${FooterOutline})` }}
        ></span>
      </footer>
      {/* End - Footer Section */}
    </>
  );
};

export default Home;
