import { memo } from "react";
import RadioButton from "../../../common/radioButton";
import InputTitle from "../../../common/inputTitle";
import { BorderOptions, OutlineOptions } from "../../../../types/designer";
import { IAppState } from "../../../../types/states";

export type OutlineOptionProps = {
  imageBase64File?: string;
  selectedOutline: OutlineOptions;
  selectedOutlineWidth: number;
  setState: React.Dispatch<React.SetStateAction<IAppState>>;
  selectedBorderOption: BorderOptions;
};

const OutlineOption = ({
  imageBase64File,
  selectedOutline,
  selectedOutlineWidth,
  setState,
  selectedBorderOption
}: OutlineOptionProps) => {
  const handleOutlineChange = (e: React.ChangeEvent<HTMLInputElement>) => setState((prevState) => ({
    ...prevState,
    selectedOutline: e.target.value as OutlineOptions
  }));

  const handleOutlineWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => setState((prevState) => ({
    ...prevState,
    selectedOutlineWidth: Number(e.target.value)
  }));

  const outlineOptions = [
    {
      id: "outline-yes",
      name: "outline-type",
      value: "outlineYes",
      disabled: !imageBase64File || selectedBorderOption === "noBorder",
      checked: selectedOutline === "outlineYes",
      onChange: handleOutlineChange,
      label: "Yes",
    },
    {
      id: "outline-no",
      name: "outline-type",
      value: "outlineNo",
      disabled: !imageBase64File || selectedBorderOption === "noBorder",
      checked: selectedOutline === "outlineNo",
      onChange: handleOutlineChange,
      label: "No",
    },
  ];
  return (
    <>
      <div>
        <InputTitle title={"Outline"} />

        <div className="flex items-center justify-between gap-2">
          {/* outline option */}
          <div className="flex items-start gap-4">
            {outlineOptions.map((item, index) => (
              <RadioButton
                key={index}
                id={item.id}
                name={item.name}
                value={item.value}
                disabled={item.disabled}
                checked={item.checked}
                onChange={item.onChange}
                label={item.label}
              />
            ))}
          </div>

          {/* outline width */}
          <div className="flex items-center justify-end gap-1.5">
            <label className="text-xs text-black-200 font-normal">Width</label>
            <div className="relative">
              <input
                type="number"
                name="width"
                id="width"
                disabled={
                  !imageBase64File ||
                  selectedBorderOption === "noBorder" ||
                  selectedOutline === "outlineNo"
                }
                value={selectedOutlineWidth}
                onChange={handleOutlineWidthChange}
                min="1"
                max="18"
                onKeyDown={(e) => e.preventDefault()}
                className="peer ml-auto w-full max-w-[60px] h-[23px] px-1.5 py-1 text-sm text-black-200 font-normal border border-black-300 rounded placeholder:text-black-200/[.7] focus:outline-none focus:ring-black-300 disabled:border-black-200/[.1] disabled:bg-black-200/[.1] disabled:text-black-200/[.5]"
              />
              {/* <span className="relative before:absolute before:z-[1] before:right-[7.1px] before:top-[2.8px] before:flex before:items-center before:justify-center before:w-[18px] before:h-2.5 before:bg-white before:pointer-events-none before:text-lg before:content-['\25B4'] after:absolute after:right-[7px] after:bottom-[2px] after:flex after:items-center after:justify-center after:w-[18px] after:h-2.5 after:bg-white after:pointer-events-none after:content-['\25B4'] after:text-lg after:rotate-180 peer-disabled:before:text-black-200/[.1] peer-disabled:after:text-black-200/[.1]"></span> */}
              <span className="up-down-arrow" />
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default memo(OutlineOption);
