/** App styling */
import "./styles/App.css";

/** Routing */
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

/** Layout */
import AppLayout from "./components/layout/layout";

/** Components */
import PageNotFound from "./components/pageNotFound/pageNotFound";
import Home from "./components/home/home";
import Designer from "./components/designer/designer";
import Checkout from "./components/checkout/checkout";
import Review from "./components/review/review";
import Success from "./components/common/success";

/** Others */
import { useEffect, useState } from "react";
import { DesignerProducts } from "./services/constants";
import BwhsApiProvider from "./contexts/bwhsApiProvider";
import { IAppState } from "./types/states";
import { BrowserView, MobileView } from "react-device-detect";
import UnsupportedMobileView from "./components/mobile/unsupported";

/** App entry */
function App() {
  useEffect(() => {
    const applicationStates = sessionStorage.getItem("application-states");
    if (applicationStates) {
      setState(JSON.parse(applicationStates));
    }

    setInitializeRoutes(true);

    // eslint-disable-next-line
  }, []);

  // initial values of all the states
  const initialState: IAppState = {
    selectedFileValid: false,
    isSelectedFilePdf: false,
    imageBase64File: "",
    imageForCanvas: "",
    isPortrait: true,
    selectedBorderOption: "blackBorder",
    selectedBorderTypeOption: "wide",
    selectedOutline: "outlineYes",
    selectedOutlineWidth: 1,
    colorPrintOption: false,
    scale: 1,
    imagePositionForCanvas: { x: 0, y: 0 },
    imagePositionForPDF: { x: 0, y: 0 },
    imageScalingForPDF: { width: 1, height: 1 },
    textEditorValue: "",
    textEditorPosition: { x: 9, y: 9 },
    textEditorWidthPx: 0,
    imageCanvasDimensions: { width: 0, height: 0 },
    imageEffectiveDPI: 0,
    PDFURL: undefined,
    productSelection: DesignerProducts.HEADSHOT_PRINTS,
    selectedPaperTypeOption: "Gloss",
    selectedPromotionalUseOption: "Allowed",
    finalPDFBlob: undefined,
    canvasRect: undefined,
    quillRect: undefined,
    scalingPercentage: 100,
    selectedTextFont: "Serif",
    selectedTextColor: "white",
    selectedTextSize: "20pt",
  };

  const [state, setState] = useState<IAppState>(initialState);
  const [initializeRoutes, setInitializeRoutes] = useState(false);

  /** Application routes */
  const routes = createBrowserRouter([
    {
      path: "/",
      element: <AppLayout />,
      errorElement: <PageNotFound />,
      children: [
        { index: true, element: <Home /> },
        { path: "home", element: <Home /> },
        {
          path: "/designer",
          element: <Navigate to="/designer/prints" replace />,
        },
        {
          path: "designer/prints",
          element: (
            <Designer
              redirect={DesignerProducts.HEADSHOT_PRINTS}
              state={state}
              setState={setState}
              initialState={initialState}
            />
          ),
        },
        {
          path: "designer/digital",
          element: (
            <Designer
              redirect={DesignerProducts.DIGITAL_HEADSHOT}
              state={state}
              setState={setState}
              initialState={initialState}
            />
          ),
        },
        {
          path: "designer/design",
          element: (
            <Designer
              redirect={DesignerProducts.YOUR_DESIGN}
              state={state}
              setState={setState}
              initialState={initialState}
            />
          ),
        },
        {
          path: "designer/resume",
          element: (
            <Designer
              redirect={DesignerProducts.RESUME_8X10}
              state={state}
              setState={setState}
              initialState={initialState}
            />
          ),
        },
        {
          path: "review",
          element: (
            <Review
              state={state}
              setState={setState}
              initialState={initialState}
            />
          ),
        },
        { path: "checkout", element: <Checkout state={state} /> },
        { path: "success", element: <Success /> },
      ],
    },
  ]);
  return (
    <>
      {initializeRoutes && (
        <BwhsApiProvider>
          <BrowserView>
            <RouterProvider router={routes} />
          </BrowserView>
          <MobileView>
            <UnsupportedMobileView />
          </MobileView>
        </BwhsApiProvider>
      )}
    </>
  );
}

export default App;
