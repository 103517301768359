import React, { memo } from "react";

export type CanvasProps = {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  isPortrait: boolean;
};

const Canvas = ({
  canvasRef,
  isPortrait
}: CanvasProps) => {
  return (
    <>
      <canvas
        ref={canvasRef}
        id="myCanvas"
        width={isPortrait ? "594" : "738"} // 72 x 10.25 = 738
        height={isPortrait ? "738" : "594"} // 72 x 8.25 = 594
        className="border border-black-300 bg-gray-100"
      />
    </>
  );
};

export default memo(Canvas);
