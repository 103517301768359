import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "@mfllp/pdfmake/build/pdfmake";
import pdfFonts from "@mfllp/pdfmake/build/vfs_fonts";
import Serif from "../assets/fontsBase64/Serif";
import Roboto from "../assets/fontsBase64/Roboto";
import Arimo from "../assets/fontsBase64/Arimo";
import ArimoBold from "../assets/fontsBase64/Arimo-Bold";
import ArimoItalic from "../assets/fontsBase64/Arimo-Italic";
import ArimoBoldItalic from "../assets/fontsBase64/Arimo-BoldItalic";
import CinzelRegular from "../assets/fontsBase64/Cinzel-Regular";
import CinzelBold from "../assets/fontsBase64/Cinzel-Bold";
import NunitoRegular from "../assets/fontsBase64/Nunito-Regular";
import NunitoBold from "../assets/fontsBase64/Nunito-Bold";
import NunitoLight from "../assets/fontsBase64/Nunito-Light";
import NunitoLightItalic from "../assets/fontsBase64/Nunito-Light-Italic";
import MerriweatherRegular from "../assets/fontsBase64/Merriweather-Regular";
import MerriweatherBold from "../assets/fontsBase64/Merriweather-Bold";
import MontserratRegular from "../assets/fontsBase64/Montserrat-Regular";
import MontserratBold from "../assets/fontsBase64/Montserrat-Bold";
import ArchivoSemiCondensedRegular from "../assets/fontsBase64/Archivo-SemiCondensed-Regular";
import ArchivoSemiCondensedBold from "../assets/fontsBase64/Archivo-SemiCondensed-Bold";
import { DesignConstants } from "../services/constants";
import handleResumePdfPrint from "./pdfUtils";
import { BorderOptions, BorderTypeOptions, OutlineOptions } from "../types/designer";
import { IAppState, IPosXY, ISizeWH } from "../types/states";
import getImageFromPdf from "./pdfToImage";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake.vfs["Serif"] = Serif;
window.pdfMake.vfs["Roboto"] = Roboto;
window.pdfMake.vfs["Arimo"] = Arimo;
window.pdfMake.vfs["ArimoBold"] = ArimoBold;
window.pdfMake.vfs["ArimoItalic"] = ArimoItalic;
window.pdfMake.vfs["ArimoBoldItalic"] = ArimoBoldItalic;
window.pdfMake.vfs["CinzelRegular"] = CinzelRegular;
window.pdfMake.vfs["CinzelBold"] = CinzelBold;
window.pdfMake.vfs["NunitoRegular"] = NunitoRegular;
window.pdfMake.vfs["NunitoBold"] = NunitoBold;
window.pdfMake.vfs["NunitoLight"] = NunitoLight;
window.pdfMake.vfs["NunitoLightItalic"] = NunitoLightItalic;
window.pdfMake.vfs["MerriweatherRegular"] = MerriweatherRegular;
window.pdfMake.vfs["MerriweatherBold"] = MerriweatherBold;
window.pdfMake.vfs["MontserratRegular"] = MontserratRegular;
window.pdfMake.vfs["MontserratBold"] = MontserratBold;
window.pdfMake.vfs["ArchivoSemiCondensedRegular"] = ArchivoSemiCondensedRegular;
window.pdfMake.vfs["ArchivoSemiCondensedBold"] = ArchivoSemiCondensedBold;

pdfMake.fonts = {
  ...pdfMake.fonts,
  Serif: {
    normal: "Serif",
    bold: "Serif",
    italics: "Serif",
    bolditalics: "Serif",
  },
  Roboto: {
    normal: "Roboto",
    bold: "Roboto",
    italics: "Roboto",
    bolditalics: "Roboto",
  },
  Arimo: {
    normal: "Arimo",
    bold: "Arimo",
    italics: "Arimo",
    bolditalics: "Arimo",
  },
  ArimoBold: {
    normal: "ArimoBold",
    bold: "ArimoBold",
    italics: "ArimoBold",
    bolditalics: "ArimoBold",
  },
  ArimoItalic: {
    normal: "ArimoItalic",
    bold: "ArimoItalic",
    italics: "ArimoItalic",
    bolditalics: "ArimoItalic",
  },
  ArimoBoldItalic: {
    normal: "ArimoBoldItalic",
    bold: "ArimoBoldItalic",
    italics: "ArimoBoldItalic",
    bolditalics: "ArimoBoldItalic",
  },
  CinzelRegular: {
    normal: "CinzelRegular",
    bold: "CinzelRegular",
    italics: "CinzelRegular",
    bolditalics: "CinzelRegular",
  },
  CinzelBold: {
    normal: "CinzelBold",
    bold: "CinzelBold",
    italics: "CinzelBold",
    bolditalics: "CinzelBold",
  },
  NunitoRegular: {
    normal: "NunitoRegular",
    bold: "NunitoRegular",
    italics: "NunitoRegular",
    bolditalics: "NunitoRegular",
  },
  NunitoBold: {
    normal: "NunitoBold",
    bold: "NunitoBold",
    italics: "NunitoBold",
    bolditalics: "NunitoBold",
  },
  NunitoLight: {
    normal: "NunitoLight",
    bold: "NunitoLight",
    italics: "NunitoLight",
    bolditalics: "NunitoLight",
  },
  NunitoLightItalic: {
    normal: "NunitoLightItalic",
    bold: "NunitoLightItalic",
    italics: "NunitoLightItalic",
    bolditalics: "NunitoLightItalic",
  },
  MerriweatherRegular: {
    normal: "MerriweatherRegular",
    bold: "MerriweatherRegular",
    italics: "MerriweatherRegular",
    bolditalics: "MerriweatherRegular",
  },
  MerriweatherBold: {
    normal: "MerriweatherBold",
    bold: "MerriweatherBold",
    italics: "MerriweatherBold",
    bolditalics: "MerriweatherBold",
  },
  MontserratRegular: {
    normal: "MontserratRegular",
    bold: "MontserratRegular",
    italics: "MontserratRegular",
    bolditalics: "MontserratRegular",
  },
  MontserratBold: {
    normal: "MontserratBold",
    bold: "MontserratBold",
    italics: "MontserratBold",
    bolditalics: "MontserratBold",
  },
  ArchivoSemiCondensedRegular: {
    normal: "ArchivoSemiCondensedRegular",
    bold: "ArchivoSemiCondensedRegular",
    italics: "ArchivoSemiCondensedRegular",
    bolditalics: "ArchivoSemiCondensedRegular",
  },
  ArchivoSemiCondensedBold: {
    normal: "ArchivoSemiCondensedBold",
    bold: "ArchivoSemiCondensedBold",
    italics: "ArchivoSemiCondensedBold",
    bolditalics: "ArchivoSemiCondensedBold",
  },
};

const textStyles = {
  "ql-font-Serif": {
    font: "Serif",
  },
  "ql-font-Roboto": {
    font: "Roboto",
  },
  "ql-font-Arimo": {
    font: "Arimo",
  },
  "ql-font-ArimoBold": {
    font: "ArimoBold",
  },
  "ql-font-ArimoItalic": {
    font: "ArimoItalic",
  },
  "ql-font-ArimoBoldItalic": {
    font: "ArimoBoldItalic",
  },
  "ql-font-CinzelRegular": {
    font: "CinzelRegular",
  },
  "ql-font-CinzelBold": {
    font: "CinzelBold",
  },
  "ql-font-NunitoRegular": {
    font: "NunitoRegular",
  },
  "ql-font-NunitoBold": {
    font: "NunitoBold",
  },
  "ql-font-NunitoLight": {
    font: "NunitoLight",
  },
  "ql-font-NunitoLightItalic": {
    font: "NunitoLightItalic",
  },
  "ql-font-MerriweatherRegular": {
    font: "MerriweatherRegular",
  },
  "ql-font-MerriweatherBold": {
    font: "MerriweatherBold",
  },
  "ql-font-MontserratRegular": {
    font: "MontserratRegular",
  },
  "ql-font-MontserratBold": {
    font: "MontserratBold",
  },
  "ql-font-ArchivoSemiCondensedRegular": {
    font: "ArchivoSemiCondensedRegular",
  },
  "ql-font-ArchivoSemiCondensedBold": {
    font: "ArchivoSemiCondensedBold",
  },
  "ql-align-center": {
    alignment: "center",
  },
  "ql-align-right": {
    alignment: "right",
  },
};

// Remove 'zero-width no-break spaces' and add line breaks
function optimizeContent(htmlContent: string) {
  const withoutZWNBSP = htmlContent.replace(/\uFEFF/g, "");
  const withLineBreaks = withoutZWNBSP.replace(
    /<\/p><p(?! class="ql-align-right"><br><\/p>| class="ql-align-center"><br><\/p>|><br><\/p>)/g,
    "</p><br><p"
  );
  return withLineBreaks;
}

function adjustFontSizes(docDefinition: any, factor = 1.3333333333333333) {
  function adjustFontSize(value: number) {
    if (typeof value === "number" && !isNaN(value)) {
      return value * factor;
    }
    return value;
  }
  function traverse(obj: any) {
    for (const key in obj) {
      if (key === "fontSize") {
        obj[key] = adjustFontSize(obj[key]);
      } else if (typeof obj[key] === "object") {
        traverse(obj[key]);
      }
    }
  }
  traverse(docDefinition);
  return docDefinition;
}

const handlePrintPDF = (
  isPortrait: boolean,
  imageScalingForPDF: ISizeWH,
  imagePositionForPDF: IPosXY,
  selectedBorderOption: BorderOptions,
  selectedBorderTypeOption: BorderTypeOptions,
  selectedOutline: OutlineOptions,
  selectedOutlineWidth: number,
  textEditorValue: string,
  setState: React.Dispatch<React.SetStateAction<IAppState>>,
  purpose: string,
  isSelectedFilePdf: boolean,
  imageBase64File?: string,
  canvasRect?: DOMRect,
  textEditorElementRect?: DOMRect,
) => {
  // If no image is selected
  if (imageBase64File === undefined || imageBase64File === null) {
    alert("No image is selected. Please select an image first.");
    return;
  }

  if (imageScalingForPDF === undefined) {
    imageScalingForPDF = { width: 1, height: 1 };
  }

  if (imagePositionForPDF === undefined) {
    imagePositionForPDF = { x: 0, y: 0 };
  }

  if (isSelectedFilePdf) {
    handleResumePdfPrint(
      imageScalingForPDF,
      imagePositionForPDF,
      setState,
      purpose,
      imageBase64File,
    );
    return;
  }

  const DPI = 72;

  if (isPortrait === undefined) isPortrait = true;

  const pageWidth = isPortrait ? 8.25 * DPI : 10.25 * DPI;
  const pageHeight = isPortrait ? 10.25 * DPI : 8.25 * DPI;

  let sideBorderWidthInches = 0;
  let topBorderWidthInches = 0;
  let bottomBorderWidthInches = 0;
  let hasFrame = false;

  if (selectedBorderOption !== "noBorder") {
    hasFrame = true;
    if (isPortrait) {
      if (selectedBorderTypeOption === "wide") {
        sideBorderWidthInches = DesignConstants.PORTRAIT_SIDE_BORDER_WIDE_INCHES;
        topBorderWidthInches = DesignConstants.PORTRAIT_TOP_BORDER_WIDE_INCHES;
        bottomBorderWidthInches = DesignConstants.PORTRAIT_BOTTOM_BORDER_WIDE_INCHES;
        hasFrame = true;
      } else if (selectedBorderTypeOption === "standard") {
        sideBorderWidthInches = DesignConstants.PORTRAIT_SIDE_BORDER_STANDARD_INCHES;
        topBorderWidthInches = DesignConstants.PORTRAIT_TOP_BORDER_STANDARD_INCHES;
        bottomBorderWidthInches = DesignConstants.PORTRAIT_BOTTOM_BORDER_STANDARD_INCHES;
        hasFrame = true;
      }
    } else {
      if (selectedBorderTypeOption === "wide") {
        sideBorderWidthInches = DesignConstants.LANDSCAPE_SIDE_BORDER_WIDE_INCHES;
        topBorderWidthInches = DesignConstants.LANDSCAPE_TOP_BORDER_WIDE_INCHES;
        bottomBorderWidthInches = DesignConstants.LANDSCAPE_BOTTOM_BORDER_WIDE_INCHES;
        hasFrame = true;
      } else if (selectedBorderTypeOption === "standard") {
        sideBorderWidthInches = DesignConstants.LANDSCAPE_SIDE_BORDER_STANDARD_INCHES;
        topBorderWidthInches = DesignConstants.LANDSCAPE_TOP_BORDER_STANDARD_INCHES;
        bottomBorderWidthInches = DesignConstants.LANDSCAPE_BOTTOM_BORDER_STANDARD_INCHES;
        hasFrame = true;
      }
    }

    // Add bleed width
    sideBorderWidthInches = sideBorderWidthInches + DesignConstants.BLEED_AREA_WIDTH_INCHES;
    topBorderWidthInches = topBorderWidthInches + DesignConstants.BLEED_AREA_WIDTH_INCHES;
    bottomBorderWidthInches = bottomBorderWidthInches + DesignConstants.BLEED_AREA_WIDTH_INCHES;
  }

  const topBorderWidth = topBorderWidthInches * DPI;
  const sideBorderWidth = sideBorderWidthInches * DPI;
  const bottomBorderWidth = bottomBorderWidthInches * DPI;

  const docDefinition: any = {
    defaultStyle: {
      font: "Serif",
    },
    background: [
      {
        // Adding gray background
        canvas: [
          {
            type: "rect",
            x: 0,
            y: 0,
            w: pageWidth,
            h: pageHeight,
            color: "#f3f4f6",
            lineWidth: 0,
          },
        ],
      },
    ],
    compress: false,
    content: [],
    pageSize: { width: pageWidth, height: pageHeight },
    pageMargins: [0, 0, 0, 0],
    styles: textStyles,
  };

  // clip to the frame if it has frame
  if (hasFrame) {
    docDefinition.content.push({
      canvas: [
        {
          type: "x-saveContext",
        },
        {
          type: "x-clipToRect",
          x: sideBorderWidth,
          y: topBorderWidth,
          width: pageWidth - 2 * sideBorderWidth,
          height: pageHeight - topBorderWidth - bottomBorderWidth,
        },
      ],
    });
  }

  // image
  docDefinition.content.push({
    image: imageBase64File,
    width: pageWidth / imageScalingForPDF.width,
    height: pageHeight / imageScalingForPDF.height,
    absolutePosition: {
      x: imagePositionForPDF.x,
      y: imagePositionForPDF.y,
    },
  });

  // restore clipping
  if (hasFrame) {
    docDefinition.content.push({
      canvas: [
        {
          type: "x-restoreContext",
        },
      ],
    });
  }

  // border printing
  if (hasFrame) {
    const borderCanvasContent = [];
    borderCanvasContent.push({
      type: "rect",
      x: 0,
      y: 0,
      w: pageWidth,
      h: topBorderWidth,
      color: selectedBorderOption === "blackBorder" ? "black" : "white",
      lineWidth: 0,
      lineColor:
        selectedBorderOption === "blackBorder" ? "black" : "white",
    });
    borderCanvasContent.push({
      type: "rect",
      x: 0,
      y: 0,
      w: sideBorderWidth,
      h: pageHeight,
      color: selectedBorderOption === "blackBorder" ? "black" : "white",
      lineWidth: 0,
      lineColor:
        selectedBorderOption === "blackBorder" ? "black" : "white",
    });
    borderCanvasContent.push({
      type: "rect",
      x: pageWidth - sideBorderWidth,
      y: 0,
      w: sideBorderWidth,
      h: pageHeight,
      color: selectedBorderOption === "blackBorder" ? "black" : "white",
      lineWidth: 0,
      lineColor:
        selectedBorderOption === "blackBorder" ? "black" : "white",
    });
    borderCanvasContent.push({
      type: "rect",
      x: 0,
      y: pageHeight - bottomBorderWidth,
      w: pageWidth,
      h: bottomBorderWidth,
      color: selectedBorderOption === "blackBorder" ? "black" : "white",
      lineWidth: 0,
      lineColor:
        selectedBorderOption === "blackBorder" ? "black" : "white",
    });

    // outline printing
    if (selectedOutline === "outlineYes") {
      const outlineWidth = selectedOutlineWidth * 0.75;
      borderCanvasContent.push({
        type: "rect",
        x: sideBorderWidth - outlineWidth,
        y: topBorderWidth - outlineWidth,
        w:
          pageWidth -
          (sideBorderWidth + sideBorderWidth) +
          outlineWidth * 2,
        h: outlineWidth,
        color: selectedBorderOption === "blackBorder" ? "white" : "black",
        lineWidth: 0,
        lineColor:
          selectedBorderOption === "blackBorder" ? "white" : "black",
      });
      borderCanvasContent.push({
        type: "rect",
        x: sideBorderWidth - outlineWidth,
        y: topBorderWidth - outlineWidth,
        w: outlineWidth,
        h:
          pageHeight -
          (topBorderWidth + bottomBorderWidth) +
          outlineWidth * 2,
        color: selectedBorderOption === "blackBorder" ? "white" : "black",
        lineWidth: 0,
        lineColor:
          selectedBorderOption === "blackBorder" ? "white" : "black",
      });
      borderCanvasContent.push({
        type: "rect",
        x: sideBorderWidth - outlineWidth,
        y: pageHeight - bottomBorderWidth,
        w:
          pageWidth -
          (sideBorderWidth + sideBorderWidth) +
          outlineWidth * 2,
        h: outlineWidth,
        color: selectedBorderOption === "blackBorder" ? "white" : "black",
        lineWidth: 0,
        lineColor:
          selectedBorderOption === "blackBorder" ? "white" : "black",
      });
      borderCanvasContent.push({
        type: "rect",
        x: pageWidth - sideBorderWidth,
        y: topBorderWidth - outlineWidth,
        w: outlineWidth,
        h:
          pageHeight -
          (topBorderWidth + bottomBorderWidth) +
          outlineWidth * 2,
        color: selectedBorderOption === "blackBorder" ? "white" : "black",
        lineWidth: 0,
        lineColor:
          selectedBorderOption === "blackBorder" ? "white" : "black",
      });
    }

    docDefinition.content.push({
      canvas: borderCanvasContent,
    });
  }

  // text printing
  if (textEditorValue !== "" && textEditorValue !== "<p><br></p>") {
    let elementRect;
    if (textEditorElementRect) {
      elementRect = textEditorElementRect;
    } else {
      elementRect = document
        ?.querySelector(".ql-container.ql-snow")
        ?.getBoundingClientRect();
    }
    if (elementRect && canvasRect) {
      const relativeTop = elementRect.top - canvasRect.top;
      const relativeLeft = elementRect.left - canvasRect.left;
      const textEditorWidth =
        pageWidth -
        (selectedBorderOption === "noBorder"
          ? 2 * DesignConstants.LANDSCAPE_SIDE_BORDER_STANDARD_INCHES * DPI
          : 2 * sideBorderWidth);
      const textContent = adjustFontSizes(
        htmlToPdfmake(optimizeContent(textEditorValue))
      );
      docDefinition.content.push({
        absolutePosition: {
          x: relativeLeft,
          y: relativeTop,
        },
        columns: [
          {
            text: textContent,
            fontSize: 13.33, // 10pt => 13.33px
            preserveLeadingSpaces: true,
            preserveTrailingSpaces: true,
            color: "white",
            width: textEditorWidth,
          },
        ],
      });
    }
  }

  if (purpose === "proof") {
    docDefinition["watermark"] = {
      text: "BWAY HEADSHOTS",
      color: "gray",
      opacity: 0.5,
      bold: true,
      italics: false,
    };
  }

  if (purpose === "review") {
    const generatedPDF = pdfMake.createPdf(docDefinition);
    // generatedPDF.getBase64((base64) => {
    //   const canvas = document.createElement("canvas");
    //   // const pdfjsLib = window['pdfjs-dist/build/pdf'];
    //   pdfjs.getDocument({ data: atob(base64) }).promise.then((pdf) => {
    //     pdf.getPage(1).then((page) => {
    //       const viewport = page.getViewport({ scale: 1 });
    //       const canvasContext = canvas.getContext("2d");
    //       canvas.height = viewport.height;
    //       canvas.width = viewport.width;
    //       const renderContext = {
    //         canvasContext,
    //         viewport,
    //       };
    //       page.render(renderContext).promise.then(() => {
    //         const imgData = canvas.toDataURL("image/jpeg");
    //         setState((prevState) => ({
    //           ...prevState,
    //           PDFURL: imgData
    //         }));
    //       });
    //     });
    //   });
    //   canvas.remove();
    // });

    generatedPDF.getBlob((blob) => {
      getImageFromPdf(blob).then((pdfImg) => {
        setState((prevState) => ({
          ...prevState,
          PDFURL: pdfImg,
          finalPDFBlob: blob
        }));
      });
    });
  } else if (purpose === "proof") {
    pdfMake.createPdf(docDefinition).download("BWAY Headshots - Proof");
  } else {
    pdfMake.createPdf(docDefinition).download("BWAY Headshots");
  }

};

export default handlePrintPDF;
