// @ts-ignore
import * as pdfjsLib from "pdfjs-dist/webpack.mjs";
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker'

// const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker');


// pdfjsLib.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker');

async function getImageFromPdf(pdfFileOrBytes: File | Uint8Array | Blob, scale = 1) {
  let src;
  if (pdfFileOrBytes instanceof File || pdfFileOrBytes instanceof Blob) {
    src = URL.createObjectURL(pdfFileOrBytes);
  } else {
    src = pdfFileOrBytes;
  }

  const pdf = await pdfjsLib.getDocument(src).promise;
  const page = await pdf.getPage(1);
  const viewport = page.getViewport({ scale: scale });
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = viewport.width;
  canvas.height = viewport.height;
  await page.render({ canvasContext: context!, viewport }).promise;
  const image = canvas.toDataURL("image/png");
  canvas.remove();
  return image;
}

export default getImageFromPdf;
