import React, { useEffect } from "react";
import FormCarousel from "./formCarousel";
import Logo from "../../assets/images/bway-headshots-logo.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../common/confirmModel";
import { DesignerProducts, MIN_ORDER_QUANTITY_RESUME, OrderConstants } from "../../services/constants";
import { IProductPriceRequest } from "../../services/bwhsApiClient";
import { useBwhsApi } from "../../contexts/bwhsApiProvider";
import { IInvoiceEntry, IShippingPackageSize, IShippingWeight } from "../../models";
import { IAppState, ICheckoutState } from "../../types/states";
import { IErrorInfo } from "../../types/errorInfo";

export type CheckoutProps = {
  state: IAppState;
};

const Checkout = ({
  state
}: CheckoutProps) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [errorInfo, setErrorInfo] = useState<IErrorInfo>({
    title: "",
    message: "",
    cancelBox: true,
    trueMessage: "Okay",
    falseMessage: undefined,
  });

  const initialValues: ICheckoutState = {
    quantity: 1,
    minQuantity: 1,
    selectPickUp: true,
    expeditedShipping: false,
    selectedShippingOption: 0,
    selectedDate: new Date(),
    sameAsBillingAddress: false,
    shippingData: {
      packageWeight: {} as IShippingWeight,
      packageSize: {} as IShippingPackageSize,
      rates: [],
    },
    unitPrice: "0.00",
    invoiceList: [] as IInvoiceEntry[],
    invoiceTotal: 0,
    shippingAddress: {
      street1: "",
      street2: "",
      city: "",
      state: "AL",
      country: "US",
      zipcode: "",
    },
    billingAddress: {
      street1: "",
      street2: "",
      city: "",
      state: "AL",
      country: "US",
      zipcode: "",
    },
    totalQuantityPrice: "0.00",
    userInfo: {
      name: "",
      email: "",
      phone: "",
    },
    currentIndex: 1,
    customerID: "",
    userID: "",
    orderID: "",
    finalPdfID: "",
    uploadedFileID: "",
    pdfDataID: "",
    productSelection: "",
  };

  const navigate = useNavigate();
  const bwhsApi = useBwhsApi();

  const [checkoutState, setCheckoutState] = useState<ICheckoutState>(initialValues);
  const [steps, setSteps] = useState([
    { label: "User Information" },
    { label: "Shipping Details" },
    { label: "Order Summary" },
  ]);

  const handleConfirmation = () => {
    setShowConfirm(false);
  };

  const callErrorDialogueBox = (errorMessage: IErrorInfo) => {
    setErrorInfo({
      title: errorMessage.title,
      message: errorMessage.message,
      cancelBox: errorMessage.cancelBox,
      trueMessage: errorMessage.trueMessage,
      falseMessage: errorMessage.falseMessage,
    });
    setShowConfirm(true);
  };

  useEffect(() => {
    let ignore = false;
    if (state.PDFURL === undefined) {
      sessionStorage.removeItem("application-states");
      sessionStorage.removeItem("checkout-states");
      sessionStorage.removeItem("show-shipping-rates");
      navigate("/", { replace: true });
    }

    const sessionCheckoutStates = sessionStorage.getItem("checkout-states");
    if (sessionCheckoutStates) {
      const checkoutStates = JSON.parse(sessionCheckoutStates);
      setCheckoutState({
        ...checkoutStates,
        selectedDate: new Date(checkoutStates.selectedDate),
      });
      return;
    }

    const productSelection = state.productSelection;
    if (productSelection === DesignerProducts.DIGITAL_HEADSHOT) {
      const value = [{ label: "User Information" }, { label: "Order Summary" }];
      setSteps(value);
    }
    let qty = checkoutState.quantity;
    let productSubCode;
    if (productSelection === DesignerProducts.RESUME_8X10) {
      if (state.colorPrintOption) {
        productSubCode = "color";
      } else {
        productSubCode = "bw";
      }
      setCheckoutState((prevState) => ({
        ...prevState,
        minQuantity: MIN_ORDER_QUANTITY_RESUME,
        quantity: MIN_ORDER_QUANTITY_RESUME
      }));
      qty = MIN_ORDER_QUANTITY_RESUME;
    }
    let priceParameters: IProductPriceRequest = {
      appProductCode: productSelection,
      appProductSubCode: productSubCode,
      quantity: qty
    };
    const abortController = new AbortController();
    bwhsApi.getProductPrice(priceParameters, abortController)
      .then((obj) => {
        if (ignore) {
          return;
        }

        const productInfo = obj.product;
        const priceData = obj.price;
        const pricePerUnit = priceData.unit_amount;
        const itemPrice = (qty * pricePerUnit) / 100;
        const invoiceEntry: IInvoiceEntry = {
          sortIndex: OrderConstants.INVOICE_INDEX_PRODUCT,
          key: productInfo.id,
          name: productInfo.name,
          value: itemPrice,
          productInfo,
          priceData,
          quantity: qty,
        };
        const array: IInvoiceEntry[] = [];
        array.push(invoiceEntry);
        setCheckoutState((prevState) => ({
          ...prevState,
          invoiceList: array,
          invoiceTotal: itemPrice,
        }));
      })
      .catch((error: Error) => {
        if (ignore) {
          return;
        }

        // let errorMessage = "Could not process the request";
        // if (error.response.status === 404) {
        //   const data = error.response.data;
        //   if (data.error) {
        //     errorMessage = data.error;
        //   }
        // }
        callErrorDialogueBox({
          title: "Something went wrong!",
          message: error.message,
          cancelBox: false,
          trueMessage: "Okay",
        });
      });

    return () => {
      abortController.abort();
      ignore = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {showConfirm && (
        <ConfirmModal
          handleConfirmation={handleConfirmation}
          data={{
            title: errorInfo.title,
            message: errorInfo.message,
            cancelBox: errorInfo.cancelBox,
            trueMessage: errorInfo.trueMessage,
            falseMessage: errorInfo.falseMessage,
          }}
        />
      )}
      <div className="h-screen flex flex-col">
        <div className="">
          <div className="h-16 w-full shadow-lg px-6 z-10 py-1 sticky top-0 flex justify-center items-center bg-white border-b-2 border-black-400">
            <button
              className="font-medium h-fit absolute left-8"
              onClick={() => navigate("/review")}
            >
              {"< Back to Review Page"}
            </button>
            <img src={Logo} className="h-full" alt="" />
          </div>
          <div className="h-16 flex items-center w-full mt-3 justify-center">
            {steps.map((step, index) => (
              <div key={index + 1} className="flex items-center">
                <div className="flex flex-col items-center">
                  <div
                    className={`w-12 h-8 flex items-center font-bold justify-center rounded-full text-white ${checkoutState.currentIndex >= index + 1
                      ? "bg-blue-500"
                      : "bg-gray-300"
                      }`}
                  >
                    {index + 1}
                  </div>
                  <div className="text-sm mt-2">{step.label}</div>
                </div>
                {index < steps.length - 1 && (
                  <div className={`flex-auto border-t-2 w-20 border-gray-400`}>
                    {"\u00A0"}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="overflow-hidden w-full">
          <FormCarousel
            state={state}
            checkoutState={checkoutState}
            setCheckoutState={setCheckoutState}
            PDFURL={state.PDFURL}
            callErrorDialogueBox={callErrorDialogueBox}
          />
        </div>
      </div>
    </>
  );
};

export default Checkout;
