import {
  PDFDocument,
  StandardFonts,
  rgb,
  popGraphicsState,
  pushGraphicsState,
  concatTransformationMatrix,
  scale,
  translate,
} from "pdf-lib";
import download from "downloadjs";
import getImageFromPdf from "./pdfToImage";
import { IAppState } from "../types/states";

const handleResumePdfPrint = async (
  imageScalingForPDF: { width: number; height: number },
  imagePositionForPDF: { x: number; y: number },
  setState: React.Dispatch<React.SetStateAction<IAppState>>,
  purpose: string,
  imageBase64File: string
) => {
  const pdfDoc = await PDFDocument.load(imageBase64File);

  // Get pages
  const pages = pdfDoc.getPages();

  // Remove all pages except the first one
  if (pages.length > 1) {
    for (let i = 1; i < pages.length; i++) {
      pdfDoc.removePage(1);
    }
  }

  // work on the first page
  const firstPage = pages[0];

  const originalPageSize = firstPage.getSize();
  const originalPageWidth = originalPageSize.width;
  const originalPageHeight = originalPageSize.height;

  const newPageWidth = 594; //576;
  const newPageHeight = 738; //720;
  const newContentWidth = newPageWidth / imageScalingForPDF.width;
  const newContentHeight = newPageHeight / imageScalingForPDF.height;
  const sclX = newContentWidth / originalPageWidth;
  const sclY = newContentHeight / originalPageHeight;

  // set the new size
  firstPage.setSize(newPageWidth, newPageHeight);
  firstPage.scaleContent(sclX, sclY);
  firstPage.translateContent(
    imagePositionForPDF.x,
    newPageHeight - originalPageHeight * sclY - imagePositionForPDF.y
  );

  if (purpose === "proof") {
    const waterFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    const waterText = "BWAY HEADSHOTS";
    const waterSize = 72 * 1.2;
    const waterWidth = waterFont.widthOfTextAtSize(waterText, waterSize);
    const waterHeight = waterFont.heightAtSize(waterSize);
    const waterX = newPageWidth / 2;
    const waterY = newPageHeight / 2;
    const waterAngleRad = Math.atan(newPageHeight / newPageWidth);

    firstPage.pushOperators(
      pushGraphicsState(),
      translate(
        -imagePositionForPDF.x / sclX,
        -(newPageHeight - originalPageHeight * sclY - imagePositionForPDF.y)
      ),
      scale(1 / sclX, 1 / sclY),
      concatTransformationMatrix(1, 0, 0, 1, waterX, waterY),
      concatTransformationMatrix(
        Math.cos(waterAngleRad),
        Math.sin(waterAngleRad),
        -Math.sin(waterAngleRad),
        Math.cos(waterAngleRad),
        0,
        0
      ),
      concatTransformationMatrix(1, 0, 0, 1, -1 * waterX, -1 * waterY)
    );

    // Add watermark text
    firstPage.drawText("BWAY HEADSHOTS", {
      font: waterFont,
      x: waterX - waterWidth / 2,
      y: waterY - waterHeight / 2,
      size: waterSize,
      color: rgb(0.5, 0.5, 0.5),
      opacity: 0.5,
    });

    firstPage.pushOperators(popGraphicsState());
  }

  const pdfBytes = await pdfDoc.save();
  const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });

  if (purpose === "proof") {
    download(pdfBlob, "BWAY Headshots - Proof", "application/pdf");
  }

  if (purpose === "review") {
    // Get image of the pdf first page
    const pdfImg = await getImageFromPdf(pdfBytes);

    setState((prevState) => ({
      ...prevState,
      PDFURL: pdfImg,
      finalPDFBlob: pdfBlob,
    }));
  }
};

export default handleResumePdfPrint;
