const base64ToBlob = (dataURI: any) => {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeType = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([uint8Array], { type: mimeType });
  return blob;
};

export default base64ToBlob;
