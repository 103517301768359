export type InputTitleProps = {
  title: string;
};

const InputTitle = ({
  title
}: InputTitleProps) => {
  return (
    <>
      <label className="block text-sm text-black-200 font-medium mb-2">
        {title}
      </label>
    </>
  );
};

export default InputTitle;
