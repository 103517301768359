import CancelIcon from "../../assets/images/close-icon.svg";
import { memo } from "react";

export interface IConfirmModalData {
  title: string;
  message: string;
  cancelBox?: boolean;
  trueMessage: string;
  falseMessage?: string;
}

export type ConfirmModalProps = {
  handleConfirmation: (confirmed: boolean) => void;
  data: IConfirmModalData;
}

const ConfirmModal = ({
  handleConfirmation,
  data
}: ConfirmModalProps) => {
  return (
    <>
      <div className="fixed left-0 right-0 top-0 z-40 h-full w-full overflow-hidden bg-black-400/[.5]">
        <div className="fixed left-0 right-0 top-[50%] -translate-y-2/4 px-3 sm:px-0">
          <div className="mx-auto shadow-xl shadow-slate-600 w-full max-w-full rounded-[5px] rounded-b-[5px] bg-white sm:max-w-[410px]">
            <div className="flex items-center justify-between rounded-t-[10px] bg-blue-100 px-5 py-3">
              <h2 className="text-base text-black-200 font-semibold">
                {data.title}
              </h2>

              {data.cancelBox && (
                <button
                  type="button"
                  className="close duration-200 hover:bg-slate-300 rounded-md p-[1px]"
                  onClick={() => handleConfirmation(false)}
                >
                  <img
                    alt="close icon"
                    src={CancelIcon}
                    className="h-[18px] w-[18px] pointer-events-none"
                  />
                </button>
              )}
            </div>

            <div className="px-5 py-7">
              <p className="text-sm text-black-200 text-center font-medium">
                {data.message}
              </p>
            </div>

            <div className="flex items-center justify-around space-x-3 rounded-b-[10px] bg-blue-100 px-5 py-2">
              {data.falseMessage && (
                <button
                  id="cancel"
                  onClick={() => handleConfirmation(false)}
                  className="flex duration-200 items-center justify-center h-[40px] min-w-[120px] text-[13px] text-black-200 font-medium px-2 border border-black-200 rounded hover:bg-black-200 hover:text-white"
                >
                  {data.falseMessage}
                </button>
              )}
              <button
                id="checkOut"
                onClick={() => handleConfirmation(true)}
                className="flex items-center justify-center h-[40px] min-w-[120px] text-[13px] text-white font-medium px-2 bg-black-200 border border-black-200 rounded hover:bg-black-300 hover:text-white"
              >
                {data.trueMessage}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ConfirmModal.defaultProps = {
  confirmModel: {
    title: "Confirm",
    message: "Add your message here.",
    cancelBox: true,
    trueMessage: "Yes",
    falseMessage: "No",
  },
};

export default memo(ConfirmModal);
