import axios, { AxiosError, AxiosInstance } from "axios";
import { IInvoiceEntry, IOrderDetails, IShippingPackageSize, IShippingRates, IShippingWeight, IStripePrice, IStripeProduct } from "../models";
import { IUserBasicDetails } from "../models/userInfo";
import { IAddress } from "../models/address";
import base64ToBlob from "../util/base64ToBlob";

export interface ICheckoutRequest {
  /** Customer ID */
  customerID: string;
  /** Invoice entries */
  invoiceList: IInvoiceEntry[];
  /** User email */
  email: string;
  /** Order ID */
  orderID: string;
}

export interface ICheckoutSessionData {
  id: string
}

export interface IProductPriceRequest {
  /** App product code */
  appProductCode: string;
  /** App product sub code */
  appProductSubCode?: string;
  /** Quantity */
  quantity: number;
}

export interface IProductPriceResponse {
  product: IStripeProduct,
  price: IStripePrice
}

export interface IShippingRatesRequest {
  toCity: string;
  toState: string;
  toCountry: string;
  toPostalCode: string;
  quantity: number;
}

export interface IShippingRatesResponse {
  packageWeight: IShippingWeight;
  packageSize: IShippingPackageSize;
  rates: IShippingRates[];
}

export class BwhsApiError extends Error {
  code: string;

  constructor(code: string, message: string, options: ErrorOptions = {}) {
    super(message, options);
    this.name = "BwhsApiError";
    this.code = code;
  }
}

export default class BwhsApiClient {
  backendBaseUrl: string | undefined;
  axiosInstance: AxiosInstance;

  constructor() {
    this.backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
    this.axiosInstance = axios.create({
      baseURL: this.backendBaseUrl,
      timeout: 120000,
    });
  }

  async getShippingRates(shippingRatesRequest: IShippingRatesRequest) {
    try {
      const response = await this.axiosInstance.post<IShippingRatesResponse>(
        '/shipping-rates',
        shippingRatesRequest
      )
      if (response.status === 200) {
        return response.data;
      } else {
        throw new BwhsApiError("shipping_failed", "Failed to get shipping rates");
      }
    }
    catch (error: any) {
      throw new BwhsApiError(error.response?.data?.error, error.message);
    }
  }

  async getProductPrice(priceRequest: IProductPriceRequest, abortController?: AbortController) {
    try {
      const response = await this.axiosInstance.post<IProductPriceResponse>(
        '/product-price',
        priceRequest,
        { signal: abortController?.signal }
      )
      if (response.status === 200) {
        return response.data;
      } else {
        throw new BwhsApiError("price_failed", "Failed to get product price");
      }
    } catch (error: any) {
      if (error instanceof BwhsApiError) {
        throw error;
      } else if (error instanceof AxiosError) {
        if (error.response?.status === 404) {
          throw new BwhsApiError("product_not_found", "Product not found");
        } else {
          throw new BwhsApiError('network-error', error.message, { cause: error });
        }
      } else {
        throw new BwhsApiError('unknown-error', error.message, { cause: error });
      }
    }
  }

  async updateOrder(orderDetails: IOrderDetails, id?: string) {
    try {
      const params: any = {};
      if (id) {
        params.id = id;
      }
      const response = await this.axiosInstance.post(
        `/order`,
        orderDetails,
        { params }
      )
      if (response.status === 200) {
        return response.data;
      } else {
        throw new BwhsApiError("order_update_failed", "Failed to update order");
      }
    } catch (error: any) {
      if (error instanceof BwhsApiError) {
        throw error;
      } else if (error instanceof AxiosError) {
        if (error.response?.status === 500) {
          throw new BwhsApiError("order_update_failed", "Failed to update order");
        } else {
          throw new BwhsApiError('network-error', error.message, { cause: error });
        }
      } else {
        throw new BwhsApiError('unknown-error', error.message, { cause: error });
      }
    }
  }

  async processCheckout(checkoutRequest: ICheckoutRequest, abortController?: AbortController) {
    try {
      const response = await this.axiosInstance.post<ICheckoutSessionData>(
        '/payment',
        checkoutRequest,
        { signal: abortController?.signal }
      )
      if (response.status === 200) {
        return response.data;
      } else {
        throw new BwhsApiError("checkout_failed", "Failed to process checkout");
      }
    } catch (error: any) {
      throw new BwhsApiError(error.response?.data?.error, error.message);
    }
  }

  async createUser(userInfo: IUserBasicDetails, billingAddress: IAddress) {
    try {
      const response = await this.axiosInstance.post<string>(
        '/user/create',
        { userInfo, billingAddress }
      )
      if (response.status === 200) {
        return response.data;
      } else {
        throw new BwhsApiError("user_create_failed", "Failed to create user");
      }
    } catch (error: any) {
      throw new BwhsApiError(error.response?.data?.error, error.message);
    }
  }

  async createOrder(orderDetails: IOrderDetails, id?: string) {
    try {
      const params: any = {};
      if (id) {
        params.id = id;
      }

      const response = await this.axiosInstance.post<any>(
        '/order',
        orderDetails,
        { params }
      )
      if (response.status === 200) {
        return response.data?.id;
      } else {
        throw new BwhsApiError("order_create_failed", "Failed to create order");
      }
    } catch (error: any) {
      if (error instanceof BwhsApiError) {
        throw error;
      } else if (error instanceof AxiosError) {
        if (error.response?.status === 500) {
          throw new BwhsApiError("order_create_failed", "Failed to create order");
        } else {
          throw new BwhsApiError('network-error', error.message, { cause: error });
        }
      } else {
        throw new BwhsApiError('unknown-error', error.message, { cause: error });
      }
    }
  }

  async saveFinalPdf(pdfBlob: Blob, orderId: string) {
    try {
      const formData1 = new FormData();
      formData1.append("PDF", pdfBlob, `BWAY_HEADSHOTS_${orderId}.pdf`);

      const response = await this.axiosInstance.post<string>(
        '/pdf',
        formData1,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      if (response.status === 200) {
        return response.data;
      } else {
        throw new BwhsApiError("save_final_pdf_failed", "Failed to save final PDF");
      }
    } catch (error: any) {
      if (error instanceof BwhsApiError) {
        throw error;
      } else if (error instanceof AxiosError) {
        if (error.response?.status === 500) {
          throw new BwhsApiError("save_final_pdf_failed", "Failed to save final PDF");
        } else {
          throw new BwhsApiError('network-error', error.message, { cause: error });
        }
      } else {
        throw new BwhsApiError('unknown-error', error.message, { cause: error });
      }
    }
  }

  async saveOriginalFile(fileBase64: string, orderId: string) {
    try {
      const formData1 = new FormData();
      formData1.append("original_file", base64ToBlob(fileBase64), `Uploaded_file_${orderId}`);

      const response = await this.axiosInstance.post<string>(
        '/uploaded-file',
        formData1,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      if (response.status === 200) {
        return response.data;
      } else {
        throw new BwhsApiError("save_original_file_failed", "Failed to save original file");
      }
    } catch (error: any) {
      if (error instanceof BwhsApiError) {
        throw error;
      } else if (error instanceof AxiosError) {
        if (error.response?.status === 500) {
          throw new BwhsApiError("save_original_file_failed", "Failed to save original file");
        } else {
          throw new BwhsApiError('network-error', error.message, { cause: error });
        }
      } else {
        throw new BwhsApiError('unknown-error', error.message, { cause: error });
      }
    }
  }

  async saveHeadshotOptions(options: any) {
    try {
      const response = await this.axiosInstance.post(
        `/pdf-data`,
        options,
      )
      if (response.status === 200) {
        return response.data;
      } else {
        throw new BwhsApiError("save_headshot_options_failed", "Failed to save headshot options");
      }
    } catch (error: any) {
      if (error instanceof BwhsApiError) {
        throw error;
      } else if (error instanceof AxiosError) {
        if (error.response?.status === 500) {
          throw new BwhsApiError("save_headshot_options_failed", "Failed to save headshot options");
        } else {
          throw new BwhsApiError('network-error', error.message, { cause: error });
        }
      } else {
        throw new BwhsApiError('unknown-error', error.message, { cause: error });
      }
    }
  }
}
