/** Available products for the designer */
export const DesignerProducts = Object.freeze({
  // Headshot Prints
  HEADSHOT_PRINTS: "prints",
  // Digital Headshot
  DIGITAL_HEADSHOT: "digital",
  // Your Design
  YOUR_DESIGN: "design",
  // Resume 8x10
  RESUME_8X10: "resume",
});

export const DesignConstants = Object.freeze({
  // Portrait mode image width in inches
  PORTRAIT_WIDTH_INCHES: 8,
  // Portrait mode image height in inches
  PORTRAIT_HEIGHT_INCHES: 10,
  // Landscape mode image width in inches
  LANDSCAPE_WIDTH_INCHES: 10,
  // Landscape mode image height in inches
  LANDSCAPE_HEIGHT_INCHES: 8,
  // Bleed area width in inches
  BLEED_AREA_WIDTH_INCHES: 0.125,

  // Portrait mode standard border - side border in inches
  PORTRAIT_SIDE_BORDER_STANDARD_INCHES: 0.5,
  // Portrait mode standard border - top border in inches
  PORTRAIT_TOP_BORDER_STANDARD_INCHES: 0.5,
  // Portrait mode standard border - bottom border in inches
  PORTRAIT_BOTTOM_BORDER_STANDARD_INCHES: 1,

  // Portrait mode wide border - side border in inches
  PORTRAIT_SIDE_BORDER_WIDE_INCHES: 1.25,
  // Portrait mode wide border - top border in inches
  PORTRAIT_TOP_BORDER_WIDE_INCHES: 0.5,
  // Portrait mode wide border - bottom border in inches
  PORTRAIT_BOTTOM_BORDER_WIDE_INCHES: 1,

  // Landscape mode standard border - side border in inches
  LANDSCAPE_SIDE_BORDER_STANDARD_INCHES: 0.5,
  // Landscape mode standard border - top border in inches
  LANDSCAPE_TOP_BORDER_STANDARD_INCHES: 0.5,
  // Landscape mode standard border - bottom border in inches
  LANDSCAPE_BOTTOM_BORDER_STANDARD_INCHES: 1,

  // Landscape mode wide border - side border in inches
  LANDSCAPE_SIDE_BORDER_WIDE_INCHES: 0.5,
  // Landscape mode wide border - top border in inches
  LANDSCAPE_TOP_BORDER_WIDE_INCHES: 1,
  // Landscape mode wide border - bottom border in inches
  LANDSCAPE_BOTTOM_BORDER_WIDE_INCHES: 1.375,

  // DPI for the PDF
  PDF_DPI: 72,
});

export const OrderConstants = Object.freeze({
  // Index for the product in the invoice
  INVOICE_INDEX_PRODUCT: 0,
  // Index for the expedited shipping in the invoice
  INVOICE_INDEX_EXPEDITED_SHIPPING: 1,
  // Index for the shipping charges in the invoice
  INVOICE_INDEX_SHIPPING_CHARGES: 2,
  // Index for the handling charges in the invoice
  INVOICE_INDEX_HANDLING_CHARGES: 3,
});

export const MIN_ORDER_QUANTITY_RESUME = 30;
export const MAX_ORDER_QUANTITY = 500;
