const getMouseCoordinates = (event: WheelEvent | MouseEvent | null, canvasRef: React.RefObject<HTMLCanvasElement>) => {
  const canvas = canvasRef.current;
  let rect = new DOMRect(0, 0, 0, 0);
  let mouseX = -1;
  let mouseY = -1;
  if (canvas) {
    rect = canvas.getBoundingClientRect();
    mouseX = event ? event.clientX - rect.left : -1;
    mouseY = event ? event.clientY - rect.top : -1;
  }
  return { canvas, mouseX, mouseY, rect };
};

export default getMouseCoordinates;
