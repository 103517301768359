import React, { useState } from "react";
import ImageUpload from "./inputComponents/imageUpload";
import PrintOrientation from "./inputComponents/printOrientation";
import OutlineOption from "./inputComponents/outlineOption";
import Logo from "../../../assets/images/bway-headshots-logo.png";
import { memo } from "react";
import RadioButton from "../../common/radioButton";
import handlePrintPDF from "../../../util/handlePrintPDF";
import InputTitle from "../../common/inputTitle";
import ConfirmModal from "../../common/confirmModel";
import { useNavigate } from "react-router-dom";
import { getDesignerProductName } from "../../../services/utility";
import { DesignerProducts } from "../../../services/constants";
import { BorderOptions, BorderTypeOptions, OutlineOptions, ProductOptions } from "../../../types/designer";
import { IAppState } from "../../../types/states";

export type DesignerSideBarProps = {
  imageBase64File?: string;
  setState: React.Dispatch<React.SetStateAction<IAppState>>;
  isPortrait: boolean;
  selectedBorderOption: BorderOptions;
  selectedBorderTypeOption: BorderTypeOptions;
  selectedOutline: OutlineOptions;
  selectedOutlineWidth: number;
  colorPrintOption: boolean;
  imageScalingForPDF: any;
  imagePositionForPDF: any;
  textEditorValue: string;
  redirect: ProductOptions;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  imageEffectiveDPI: number;
  imageCanvasDimensions: any;
  initialState: IAppState;
  isSelectedFilePdf: boolean;
  productSelection: ProductOptions;
};

const DesignerSideBar = ({
  imageBase64File,
  setState,
  isPortrait,
  selectedBorderOption,
  selectedBorderTypeOption,
  selectedOutline,
  selectedOutlineWidth,
  colorPrintOption,
  imageScalingForPDF,
  imagePositionForPDF,
  textEditorValue,
  redirect,
  canvasRef,
  imageEffectiveDPI,
  imageCanvasDimensions,
  initialState,
  isSelectedFilePdf,
  productSelection,
}: DesignerSideBarProps) => {

  const [showConfirm1, setShowConfirm1] = useState(false);
  const [showConfirm2, setShowConfirm2] = useState(false);
  const navigate = useNavigate();

  const handleConfirmation1 = (confirmed: boolean) => {
    if (confirmed) {
      const canvasRectangle = canvasRef.current?.getBoundingClientRect();
      const quillRectangle = document.querySelector(".ql-container.ql-snow")?.getBoundingClientRect()
      setState((prevState) => ({
        ...prevState,
        PDFURL: undefined
      }));
      handlePrintPDF(
        isPortrait,
        imageScalingForPDF,
        imagePositionForPDF,
        selectedBorderOption,
        selectedBorderTypeOption,
        selectedOutline,
        selectedOutlineWidth,
        textEditorValue,
        setState,
        "review",
        isSelectedFilePdf,
        imageBase64File,
        canvasRectangle,
        quillRectangle,
      );
      setState((prevState) => ({
        ...prevState,
        canvasRect: canvasRectangle,
        quillRect: quillRectangle
      }));
      navigate("/review");
    }
    setShowConfirm1(false);
  };

  const handleConfirmation2 = (confirmed: boolean) => {
    if (confirmed) {
      setState(initialState);
      navigate("/");
    }
    setShowConfirm2(false);
  };

  const handleBorderOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => setState((prevState) => ({
    ...prevState,
    selectedBorderOption: e.target.value as BorderOptions
  }));

  const handleBorderTypeOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => setState((prevState) => ({
    ...prevState,
    selectedBorderTypeOption: e.target.value as BorderTypeOptions
  }));

  const handleColorPrintOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => setState((prevState) => ({
    ...prevState,
    colorPrintOption: e.target.value === "true"
  }));

  const borderOptions = [
    {
      id: "black-border",
      name: "border-option",
      value: "blackBorder",
      disabled: !imageBase64File,
      checked: selectedBorderOption === "blackBorder",
      onChange: handleBorderOptionChange,
      label: "Black Border",
    },
    {
      id: "white-border",
      name: "border-option",
      value: "whiteBorder",
      disabled: !imageBase64File,
      checked: selectedBorderOption === "whiteBorder",
      onChange: handleBorderOptionChange,
      label: "White Border",
    },
    {
      id: "no-border",
      name: "border-option",
      value: "noBorder",
      disabled: !imageBase64File,
      checked: selectedBorderOption === "noBorder",
      onChange: handleBorderOptionChange,
      label: "No Border",
    },
  ];

  const borderTypeOption = [
    {
      id: "wide-border",
      name: "border-type",
      value: "wide",
      disabled: !imageBase64File || selectedBorderOption === "noBorder",
      checked: selectedBorderTypeOption === "wide",
      onChange: handleBorderTypeOptionChange,
      label: "Wide",
    },
    {
      id: "standard-border",
      name: "border-type",
      value: "standard",
      disabled: !imageBase64File || selectedBorderOption === "noBorder",
      checked: selectedBorderTypeOption === "standard",
      onChange: handleBorderTypeOptionChange,
      label: "Standard",
    },
  ];

  return <>
    {showConfirm1 && (
      <ConfirmModal
        handleConfirmation={handleConfirmation1}
        data={{
          title: "Low quality !!!",
          message:
            "The image quality is very low for creating PDF. Do you want to continue?",
          cancelBox: true,
          trueMessage: "Continue",
          falseMessage: "No",
        }}
      />
    )}

    {showConfirm2 && (
      <ConfirmModal
        handleConfirmation={handleConfirmation2}
        data={{
          title: "Discard progress and go to home page.",
          message: "Do you want to discard the current progress?",
          cancelBox: true,
          trueMessage: "Yes, Discard",
          falseMessage: "No",
        }}
      />
    )}
    <div className="min-w-[315px] flex flex-col justify-between p-5 bg-white min-h-screen h-full">
      <div>
        {/* Logo image */}
        <div className="w-full text-center mb-2">
          <img
            src={Logo}
            alt="BWAY HEADSHOTS"
            onClick={() => {
              if (imageBase64File === "") handleConfirmation2(true);
              else setShowConfirm2(true);
            }}
            className="w-[141px] mx-auto"
          />
        </div>
        <label className="block text-xl text-black-200 font-semibold mb-2">
          Design - {getDesignerProductName(redirect)}
        </label>
        <hr />
        <br />
        {/* Image upload */}
        <ImageUpload
          setState={setState}
          canvasRef={canvasRef}
          redirect={redirect}
        />
        <div>
          <form className="space-y-4">
            {/* Select Print Orientation */}
            <PrintOrientation
              imageBase64File={imageBase64File}
              isPortrait={isPortrait}
              setState={setState}
              canvasRef={canvasRef}
              imageCanvasDimensions={imageCanvasDimensions}
              productSelection={productSelection}
            />
            {(redirect === DesignerProducts.RESUME_8X10) && (
              <>
                <div>
                  {/* B&w or Color */}
                  <InputTitle title={"B&W or Color"} />
                  <div className="flex items-start gap-4">
                    <RadioButton
                      id="bw"
                      name="bw-color"
                      value="false"
                      checked={!colorPrintOption}
                      onChange={handleColorPrintOptionChange}
                      label="B&W"
                      disabled={!imageBase64File}
                    />
                    <RadioButton
                      id="color"
                      name="bw-color"
                      value="true"
                      checked={colorPrintOption}
                      onChange={handleColorPrintOptionChange}
                      label="Color"
                      disabled={!imageBase64File}
                    />
                  </div>
                </div>
              </>
            )}
            {(redirect === DesignerProducts.HEADSHOT_PRINTS ||
              redirect === DesignerProducts.DIGITAL_HEADSHOT) && (
                <>
                  {/* Border Option */}
                  <div>
                    <InputTitle title={"Border Option"} />
                    <div className="flex items-start gap-4">
                      {borderOptions.map((item, index) => (
                        <RadioButton
                          key={index}
                          id={item.id}
                          name={item.name}
                          value={item.value}
                          disabled={item.disabled}
                          checked={item.checked}
                          onChange={item.onChange}
                          label={item.label}
                        />
                      ))}
                    </div>
                  </div>

                  {/* Border Type */}
                  <div>
                    <InputTitle title={"Border Size"} />
                    <div className="flex items-start gap-4">
                      {borderTypeOption.map((item, index) => (
                        <RadioButton
                          key={index}
                          id={item.id}
                          name={item.name}
                          value={item.value}
                          disabled={item.disabled}
                          checked={item.checked}
                          onChange={item.onChange}
                          label={item.label}
                        />
                      ))}
                    </div>
                  </div>

                  {/* Outline? */}
                  <OutlineOption
                    imageBase64File={imageBase64File}
                    selectedOutline={selectedOutline}
                    selectedOutlineWidth={selectedOutlineWidth}
                    setState={setState}
                    selectedBorderOption={selectedBorderOption}
                  />
                </>
              )}
          </form>
        </div>
        {redirect === DesignerProducts.RESUME_8X10 && (
          <>
            <br />
            <hr />
            <div className="my-3 max-w-[315px]">
              <label className="block text-gray-500 italic text-sm">
                Resume text may appear in low quality in the designer. The final prints will be high quality.
              </label>
            </div>
          </>
        )}
      </div>

      <div className="mt-auto flex items-center gap-4">
        <button
          id="cancel"
          onClick={() => {
            if (imageBase64File === "") handleConfirmation2(true);
            else setShowConfirm2(true);
          }}
          className="flex items-center justify-center h-[40px] min-w-[110px] text-[13px] text-black-200 font-medium px-2 border border-black-200 rounded transition ease-in hover:border hover:border-black-200/[.1] hover:bg-beige-100 hover:text-black-200"
        >
          Cancel
        </button>

        <button
          id="checkOut"
          disabled={!imageBase64File}
          onClick={() => {
            if (imageEffectiveDPI > 200 || isSelectedFilePdf) {
              const canvasRectangle =
                canvasRef.current?.getBoundingClientRect();
              const quillRectangle = document.querySelector(".ql-container.ql-snow")?.getBoundingClientRect();
              setState((prevState) => ({
                ...prevState,
                PDFURL: undefined
              }));
              handlePrintPDF(
                isPortrait,
                imageScalingForPDF,
                imagePositionForPDF,
                selectedBorderOption,
                selectedBorderTypeOption,
                selectedOutline,
                selectedOutlineWidth,
                textEditorValue,
                setState,
                "review",
                isSelectedFilePdf,
                imageBase64File,
                canvasRectangle,
                quillRectangle,
              );
              setState((prevState) => ({
                ...prevState,
                canvasRect: canvasRectangle,
                quillRect: quillRectangle
              }));
              navigate("/review");
            } else setShowConfirm1(true);
          }}
          className="flex items-center justify-center h-[40px] text-[13px] text-white font-medium px-12 bg-black-200 border border-black-200 rounded transition ease-in hover:border hover:border-black-200/[.1] hover:bg-beige-100 hover:text-black-200 disabled:bg-black-200/[.1] disabled:text-black-200/[.5] disabled:border-black-200/[.1]"
        >
          Next
        </button>
      </div>
    </div>
  </>;
};

export default memo(DesignerSideBar);
