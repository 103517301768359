import React, { useCallback, useState } from "react";
import { memo } from "react";
import ConfirmModal from "../../../common/confirmModel";
import getImageFromPdf from "../../../../util/pdfToImage";
import { IAppState } from "../../../../types/states";

export type ImageUploadProps = {
  setState: React.Dispatch<React.SetStateAction<IAppState>>;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  redirect: string;
};

const ImageUpload = ({
  setState,
  canvasRef,
  redirect
}: ImageUploadProps) => {
  const [showError, setShowError] = useState(false);
  const [errorTitle, setErrorTitle] = useState("Error");
  const [errorMessage, setErrorMessage] = useState("Error found.");
  const handleError = () => {
    setShowError(false);
  };
  const handleImageUploadChange = useCallback(
    (selectedFile: File) => {
      const resizeImageForCanvas = (dataUrl: string) => {
        return new Promise<string>((resolve, reject) => {
          const canvas = canvasRef.current!;
          const img = new Image();
          img.onload = function () {
            const aspectRatio = img.width / img.height;
            let newHeight: number, newWidth: number;
            if (aspectRatio > 1) {
              newHeight = canvas.height;
              newWidth = newHeight * aspectRatio;
            } else {
              newWidth = canvas.width;
              newHeight = newWidth / aspectRatio;
            }
            let x = (canvas.width - newWidth) / 2;
            let y = (canvas.height - newHeight) / 2;
            setState((prevState) => ({
              ...prevState,

              imageCanvasDimensions: {
                width: newWidth,
                height: newHeight,
              },

              imagePositionForCanvas: {
                x: x,
                y: y,
              },

              imageEffectiveDPI: Math.min(img.width, img.height) / 8.25
            }));
            if (img.width < 4000 && img.height < 4000) {
              resolve(dataUrl);
            } else {
              const canvasForImageCreation = document.createElement("canvas");
              const ctx = canvasForImageCreation.getContext("2d");
              canvasForImageCreation.width = newWidth * 2;
              canvasForImageCreation.height = newHeight * 2;
              ctx?.drawImage(img, 0, 0, newWidth * 2, newHeight * 2);
              const resizedBase64 =
                canvasForImageCreation.toDataURL("image/jpeg");
              canvasForImageCreation.remove();
              resolve(resizedBase64);
            }
          };
          img.onerror = function () {
            reject(dataUrl);
          };
          img.src = dataUrl;
        });
      };

      const fileSizeInMB = selectedFile.size / (1024 * 1024); // Convert bytes to MB
      if (fileSizeInMB > 20) {
        setErrorTitle("File size limit.");
        setErrorMessage(
          "File size exceeds 20MB. Add file of size less than 20MB."
        );
        setShowError(true);
        return;
      }
      setState((prevState) => ({
        ...prevState,
        selectedFileValid: false,
        isSelectedFilePdf: false,
        imageBase64File: "",
        imageForCanvas: "",
        scale: 1,
        textEditorValue: "",

        textEditorPosition: {
          x: 45,
          y: prevState.isPortrait ? 620 : 480,
        },

        scalingPercentage: 100
      }));
      const reader = new FileReader();
      reader.onload = function (event) {
        const selectedFileDataUrl = reader.result as string;
        if (
          selectedFile.type === "image/jpeg" ||
          selectedFile.type === "image/jpg" ||
          selectedFile.type === "image/png"
        ) {
          resizeImageForCanvas(selectedFileDataUrl)
            .then((resizedBase64) => {
              setState((prevState) => ({
                ...prevState,
                selectedFileValid: true,
                imageForCanvas: resizedBase64,
                imageBase64File: selectedFileDataUrl
              }));
            })
            .catch((error) => {
              setErrorTitle("Error !!");
              setErrorMessage(
                "Error reading the file. Try to upload another file."
              );
              setShowError(true);
              console.error("Error reading the file.", error);
            });
        } else if (selectedFile.type === "application/pdf") {
          getImageFromPdf(selectedFile, 4)
            .then((convertedToImage) => {
              resizeImageForCanvas(convertedToImage)
                .then((resizedBase64) => {
                  setState((prevState) => ({
                    ...prevState,
                    selectedFileValid: true,
                    isSelectedFilePdf: true,
                    imageForCanvas: resizedBase64,
                    imageBase64File: selectedFileDataUrl
                  }));
                })
                .catch((error) => {
                  setErrorTitle("Error !!");
                  setErrorMessage(
                    "Error reading the file. Try to upload another file."
                  );
                  setShowError(true);
                  console.error("Error reading the file.", error);
                });
            })
            .catch((error) => {
              setErrorTitle("Error !!");
              setErrorMessage(
                "Error reading the file. Try to upload another file."
              );
              setShowError(true);
              console.error("Error reading the file.", error);
            });
        } else if (
          selectedFile.type === "image/gif" ||
          selectedFile.type === "image/bmp" ||
          selectedFile.type === "image/svg+xml" ||
          selectedFile.type === "image/webp"
        ) {
          const img = new Image();
          img.onload = function () {
            let hiddenCanvas = document.createElement("canvas");
            const ctx = hiddenCanvas.getContext("2d");
            hiddenCanvas.width = img.width;
            hiddenCanvas.height = img.height;
            ctx?.drawImage(img, 0, 0);
            const imageBase64URL = hiddenCanvas.toDataURL("image/jpg");
            hiddenCanvas.remove();
            resizeImageForCanvas(imageBase64URL)
              .then((resizedBase64) => {
                setState((prevState) => ({
                  ...prevState,
                  selectedFileValid: true,
                  imageForCanvas: resizedBase64,
                  imageBase64File: imageBase64URL
                }));
              })
              .catch((error) => {
                setErrorTitle("Error !!");
                setErrorMessage(
                  "Error reading the file. Try to upload another file."
                );
                setShowError(true);
                console.error("Error reading the file.", error);
              });
          };
          img.onerror = function () {
            setErrorTitle("Error !!");
            setErrorMessage(
              "Error reading the file. Try to upload another file."
            );
            setShowError(true);
          };
          img.src = selectedFileDataUrl;
        } else {
          setErrorTitle("Invalid File Type");
          setErrorMessage(
            "The image is of invalid type. Try uploading .jpg, .jpeg, .png, etc. image format."
          );
          setShowError(true);
        }
      };
      reader.onerror = (error) => {
        setErrorTitle("Error !!");
        setErrorMessage("Error reading the file. Try to upload another file.");
        setShowError(true);
        console.error("Error reading the file.", error);
      };

      reader.readAsDataURL(selectedFile);
    },
    [setState, canvasRef]
  );
  return (
    <>
      {showError && (
        <ConfirmModal
          handleConfirmation={handleError}
          data={{
            title: errorTitle,
            message: errorMessage,
            cancelBox: false,
            trueMessage: "Okay",
          }}
        />
      )}
      <div className="relative mb-2.5">
        <input
          onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
              handleImageUploadChange(e.target.files[0]);
            }
          }}
          type="file"
          name="upload-file"
          id="upload-file"
          accept={`.jpg,.jpeg,.png,.gif,.svg,.webp,.bmp,${redirect === "resume" ? "application/pdf" : ""
            }`}
          className="hidden w-full"
        />
        <label
          htmlFor="upload-file"
          className="cursor-pointer w-full px-3 h-[40px] flex items-center justify-center rounded bg-black-200 text-white text-base font-medium transition ease-in hover:border hover:border-black-200/[.1] hover:bg-beige-100 hover:text-black-200"
        >
          Upload File
        </label>
      </div>
    </>
  );
};

export default memo(ImageUpload);
