import Logo from "../../assets/images/bway-headshots-logo.png";
const UnsupportedMobileView = () => {
  return (
    <div>
      <div className="flex space-y-6 flex-col justify-center items-center h-screen w-screen bg-beige-100">
        <img src={Logo} alt="BWAY HEADSHOTS" className="w-[200px] mx-auto" />
        <div className="text-2xl text-center px-4">
          <b>Sorry! </b>
          <span>
            We do not support mobile devices. Please open this site on your desktop / laptop.
          </span>
        </div>
      </div>
    </div>
  );
};

export default UnsupportedMobileView;
