import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/bway-headshots-logo.png";
import SuccessTick from "../../assets/images/success-tick-mark.gif";

const Success = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const session = sessionStorage.getItem("checkout-states");
    if (!session) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <img src={Logo} alt="" className="absolute w-56 m-6" />
      <div className="flex p-9 h-screen flex-col space-y-4 items-center bg-white">
        <img className="h-64 pointer-events-none" src={SuccessTick} alt="" />
        <h1 className="text-3xl font-serif text-gray-700">
          Your payment is successful.
        </h1>
        <h2 className="pt-8 text-2xl font-sans">
          Thank you for your order!
          <br />
          Your order is in process.
        </h2>
        <button
          className="text-blue-500 font-sans text-xl underline hover:text-blue-600"
          onClick={() => {
            sessionStorage.removeItem("application-states");
            sessionStorage.removeItem("checkout-states");
            sessionStorage.removeItem("show-shipping-rates");
            navigate("/", { replace: true });
          }}
        >
          Go back to the Home Page
        </button>
      </div>
    </>
  );
};

export default Success;
