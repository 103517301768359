import React, { useState } from "react";
import UserInfoForm from "./forms/userInfo";
import DeliveryInfoForm from "./forms/deliveryInfo";
import OrderSummary from "./forms/orderSummary";
import { DesignerProducts } from "../../services/constants";
import { IAppState, ICheckoutState } from "../../types/states";
import { IErrorInfo } from "../../types/errorInfo";

export type FormCarouselProps = {
  state: IAppState;
  checkoutState: ICheckoutState;
  setCheckoutState: React.Dispatch<React.SetStateAction<ICheckoutState>>;
  PDFURL?: string;
  callErrorDialogueBox: (errorMessage: IErrorInfo) => void;
};
const FormCarousel = ({
  state,
  checkoutState,
  setCheckoutState,
  PDFURL,
  callErrorDialogueBox
}: FormCarouselProps) => {
  const [busy, setBusy] = useState(false);
  const product = state.productSelection;
  const items = [
    {
      component: (
        <UserInfoForm
          state={state}
          checkoutState={checkoutState}
          setCheckoutState={setCheckoutState}
          callErrorDialogueBox={callErrorDialogueBox}
          busy={busy}
          setBusy={setBusy}
        />
      ),
    },
    product !== DesignerProducts.DIGITAL_HEADSHOT
      ? {
        component: (
          <DeliveryInfoForm
            state={state}
            checkoutState={checkoutState}
            setCheckoutState={setCheckoutState}
            callErrorDialogueBox={callErrorDialogueBox}
            busy={busy}
            setBusy={setBusy}
          />
        ),
      }
      : null,
    {
      component: (
        <OrderSummary
          state={state}
          product={product}
          checkoutState={checkoutState}
          setCheckoutState={setCheckoutState}
          PDFURL={PDFURL}
          callErrorDialogueBox={callErrorDialogueBox}
          setBusy={setBusy}
        />
      ),
    },
  ];

  return (
    <>
      {busy && (
        <div className="w-full h-full fixed top-0 left-0 bg-white opacity-75 z-50">
          <div className="flex justify-center items-center mt-[50vh]">
            <div className="loader"></div>
          </div>
        </div>
      )}
      <div
        className="w-full h-full whitespace-nowrap flex transition-transform duration-1000 ease-out"
        style={{
          transform: `translateX(-${(checkoutState.currentIndex - 1) * 100}%)`,
        }}
      >
        {items.map(
          (item, index) =>
            item &&
            (checkoutState.currentIndex - 1 === index ||
              (product === DesignerProducts.DIGITAL_HEADSHOT &&
                checkoutState.currentIndex === 2) ? (
              <div
                key={index}
                className="w-full h-full flex-shrink-0 flex overflow-x-auto items-start z-20 justify-center text-gray-700"
              >
                {item.component}
              </div>
            ) : (
              <div key={index} className="w-full h-full flex-shrink-0" />
            ))
        )}
      </div>
    </>
  );
};

export default FormCarousel;
