import { loadStripe } from "@stripe/stripe-js";
import { DesignerProducts } from "../../../services/constants";
import { useNavigate } from "react-router-dom";
import { useBwhsApi } from "../../../contexts/bwhsApiProvider";
import { IAppState, ICheckoutState } from "../../../types/states";
import React from "react";
import { IErrorInfo } from "../../../types/errorInfo";
import { ProductOptions } from "../../../types/designer";

const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "";

// const stripe = await loadStripe(publishableKey);

export type OrderSummaryProps = {
  state: IAppState;
  product: ProductOptions;
  checkoutState: ICheckoutState;
  setCheckoutState: React.Dispatch<React.SetStateAction<ICheckoutState>>;
  PDFURL?: string;
  callErrorDialogueBox: (errorMessage: IErrorInfo) => void;
  setBusy: React.Dispatch<React.SetStateAction<boolean>>;
};

const OrderSummary = ({
  state,
  product,
  checkoutState,
  setCheckoutState,
  PDFURL,
  callErrorDialogueBox,
  setBusy
}: OrderSummaryProps) => {
  const {
    quantity,
    selectPickUp,
    expeditedShipping,
    selectedShippingOption,
    selectedDate,
    shippingData,
    unitPrice,
    invoiceList,
    invoiceTotal,
    shippingAddress,
    billingAddress,
    totalQuantityPrice,
    userInfo,
    customerID,
    orderID,
  } = checkoutState;

  const stripePromise = loadStripe(publishableKey);
  const bwhsApi = useBwhsApi();
  const navigate = useNavigate();
  const handleOrderSummarySubmit = async () => {
    sessionStorage.setItem("checkout-states", JSON.stringify(checkoutState));
    const checkoutData = {
      customerID: customerID,
      invoiceList: invoiceList,
      email: userInfo.email,
      orderID
    };
    try {
      setBusy(true);
      const response = await bwhsApi.processCheckout(
        checkoutData
      );
      setBusy(false);
      stripePromise
        .then((stripe) =>
          stripe!.redirectToCheckout({ sessionId: response.id })
        )
    } catch (error: any) {
      setBusy(false);
      console.error(error);
      const errorTitle =
        error?.response?.data?.error === "customer_tax_location_invalid"
          ? "Invalid billing address !"
          : error.message + " !";

      const errorMessage =
        error?.response?.data?.error === "customer_tax_location_invalid"
          ? "Given billing address is invalid. Please enter a valid address."
          : "There's some interruption while connecting. Please try again after some time.";

      callErrorDialogueBox({
        title: errorTitle,
        message: errorMessage,
        cancelBox: false,
        trueMessage: "Okay",
      });
    }
  };

  return <>
    <div className="flex mt-5 bg-white w-5/6 overflow-hidden rounded-lg shadow-lg">
      <div className="w-3/4 mx-auto px-10 p-6 pt-3 border-r-2">
        <h2 className="text-2xl text-center my-4 font-medium uppercase">
          Order Summary
        </h2>
        <hr />
        <div className="mt-8 flex">
          <img
            className="md:h-[250px] h-[100px] aspect-auto mr-4"
            src={PDFURL}
            style={{ boxShadow: "0 3px 12px rgba(0, 0, 0, 0.2)" }}
            alt="Headshot Print here"
          />
          <div className="flex space-x-7 justify-around w-4/5 pr-10">
            <div className="w-full overflow-hidden text-wrap break-all">
              <h2 className="text-xl font-semibold">User Details</h2>
              <div className="flex space-x-2">
                <label>Name: {userInfo.name}</label>
              </div>

              <div className="flex flex-col mb-3">
                <label>Mail: {userInfo.email}</label>
                <label>Phone: {userInfo.phone}</label>
              </div>
              <h2 className="text-xl font-semibold">Billing Address</h2>
              <div className="flex flex-col">
                <label>{billingAddress.street1}</label>
                <label>{billingAddress.street2}</label>
                <div>
                  <label>{billingAddress.city}</label>
                </div>
                <div>
                  <label>{billingAddress.state}, </label>
                  <label>{billingAddress.country}</label>
                </div>
                <label>{billingAddress.zipcode}</label>
              </div>
            </div>
            <div className="w-full overflow-hidden text-wrap break-all">
              <h2 className="text-xl font-semibold ">
                Order Details
              </h2>
              <div className="flex flex-col mb-3">
                {product !== DesignerProducts.DIGITAL_HEADSHOT && (
                  <>
                    <label>
                      Quantity: {" "}
                      {quantity +
                        " ( $" +
                        unitPrice +
                        " * " +
                        quantity +
                        " = $" +
                        totalQuantityPrice +
                        " )"}{" "}
                    </label>
                    {product === DesignerProducts.RESUME_8X10 && (
                      <label>
                        Color Print: {state.colorPrintOption ? "Yes" : "No"}
                      </label>
                    )}
                    <label>
                      Rush Order: {expeditedShipping ? "Yes" : "No"}
                    </label>
                    {selectPickUp && (
                      <label>
                        Pick Up: {selectPickUp ? "Yes" : "No"}
                      </label>
                    )}
                  </>
                )}
                <label>
                  {selectPickUp ? "Pick Up Date" : "Shipping Date"}: {" "}
                  {selectedDate.toDateString()}
                </label>
                {!selectPickUp && (
                  <>
                    <label>
                      Shipping Method: {" "}
                      {shippingData.rates.length &&
                        shippingData.rates[selectedShippingOption] &&
                        shippingData.rates[selectedShippingOption].serviceName}
                    </label>
                    <label>
                      Shipping Rate: {" $"}
                      {shippingData.rates.length &&
                        shippingData.rates[selectedShippingOption] &&
                        shippingData.rates[selectedShippingOption].shipmentCost}
                    </label>
                  </>
                )}
                {product === DesignerProducts.DIGITAL_HEADSHOT && (
                  <>
                    <label>Product Type: Digital Print (PDF)</label>
                  </>
                )}
              </div>
              {!selectPickUp && (
                <>
                  <h2 className="text-xl font-semibold">Shipping Address</h2>
                  <div className="flex flex-col">
                    <label>{shippingAddress.street1}</label>
                    <label>{shippingAddress.street2}</label>
                    <div>
                      <label>{shippingAddress.city}</label>
                    </div>
                    <div>
                      <label>{shippingAddress.state}, </label>
                      <label>{shippingAddress.country}</label>
                    </div>
                    <label>{shippingAddress.zipcode}</label>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="mt-10">
          <button
            onClick={() => {
              if (PDFURL === undefined) {
                sessionStorage.removeItem("application-states");
                sessionStorage.removeItem("checkout-states");
                sessionStorage.removeItem("show-shipping-rates");
                navigate("/");
                return;
              }
              setCheckoutState((prevState) => ({
                ...prevState,

                currentIndex:
                  product === DesignerProducts.DIGITAL_HEADSHOT ? 1 : 2
              }));
            }}
            className="bg-blue-500 w-fit text-white px-10 py-4 font-semibold rounded-lg"
          >
            Back
          </button>
        </div>
      </div>
      <div className="w-1/4 bg-[#E2DBD0] flex items-center justify-between py-6 flex-col space-y-7">
        <div className="w-full">
          <h2 className="text-2xl text-center font-medium uppercase">
            Invoice
          </h2>
          <div className="bg-gray-100 m-5 rounded-lg p-5 px-9">
            {invoiceList
              .sort((a, b) => a.sortIndex - b.sortIndex)
              .map((item, index) => (
                <div key={index} className="flex justify-between mb-3">
                  <div>{item.name}</div>
                  <div>$ {item.value.toFixed(2)}</div>
                </div>
              ))}
            <hr />
            <br />
            <div className="flex justify-between">
              <div>Total</div>
              <div>$ {invoiceTotal.toFixed(2)}</div>
            </div>
          </div>
          <div className="italic px-6 text-wrap text-red-500">
            Taxes will be shown on the payment page
          </div>
          <div className="p-6 text-wrap">
            Promotion codes can be entered on the payment page
          </div>
        </div>
        <button
          onClick={handleOrderSummarySubmit}
          className="bg-blue-500 w-fit text-white px-10 py-4 font-semibold rounded-lg"
        >
          Proceed to Pay
        </button>
      </div>
    </div>
  </>;
};


export default OrderSummary;
