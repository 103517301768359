import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { CanadaStates, USAStates } from "../../../services/utility";
import {
  DesignerProducts,
  MAX_ORDER_QUANTITY,
  OrderConstants,
} from "../../../services/constants";
import { useNavigate } from "react-router-dom";
import { useBwhsApi } from "../../../contexts/bwhsApiProvider";
import { IProductPriceRequest } from "../../../services/bwhsApiClient";
import { IInvoiceEntry, IOrderDetails, IShippingPackageSize, IShippingWeight, IStripePrice, IStripeProduct } from "../../../models";
import { IAppState, ICheckoutState } from "../../../types/states";
import { IErrorInfo } from "../../../types/errorInfo";

export type DeliveryInfoFormProps = {
  state: IAppState;
  checkoutState: ICheckoutState;
  setCheckoutState: React.Dispatch<React.SetStateAction<ICheckoutState>>;
  callErrorDialogueBox: (errorMessage: IErrorInfo) => void;
  busy: boolean;
  setBusy: React.Dispatch<React.SetStateAction<boolean>>;
}
const DeliveryInfoForm = ({
  state,
  checkoutState,
  setCheckoutState,
  callErrorDialogueBox,
  busy,
  setBusy
}: DeliveryInfoFormProps) => {
  const bwhsApi = useBwhsApi();
  const shippingAddressFormRef = useRef<HTMLFormElement>(null);
  const [showShippingRates, setShowShippingRates] = useState(false);
  const [invalidZipcode, setInvalidZipcode] = useState("");
  const {
    quantity,
    minQuantity,
    selectPickUp,
    expeditedShipping,
    selectedShippingOption,
    selectedDate,
    sameAsBillingAddress,
    shippingData,
    unitPrice,
    invoiceTotal,
    shippingAddress,
    billingAddress,
    totalQuantityPrice,
    invoiceList,
    orderID,
  } = checkoutState;
  const { productSelection } = state;
  const [expeditedShippingInfo, setExpeditedShippingInfo] = useState({
    productInfo: {} as IStripeProduct,
    priceData: {} as IStripePrice,
  });
  const [handlingChargeInfo, setHandlingChargeInfo] = useState({
    productInfo: {} as IStripeProduct,
    priceData: {} as IStripePrice,
  });

  const navigate = useNavigate();
  const today = new Date();
  const expeditedDate = new Date(today);
  const day = today.getDay();
  if (day === 5) {
    expeditedDate.setDate(today.getDate() + 3);
  } else if (day === 6) {
    expeditedDate.setDate(today.getDate() + 2);
  } else {
    expeditedDate.setDate(today.getDate() + 1);
  }

  const incrementQuantity = () => {
    if (quantity < MAX_ORDER_QUANTITY) {
      setShowShippingRates(false);
      setCheckoutState((prevState) => ({
        ...prevState,

        invoiceList: prevState.invoiceList.filter(
          (item) => item.sortIndex !== OrderConstants.INVOICE_INDEX_SHIPPING_CHARGES &&
            item.sortIndex !== OrderConstants.INVOICE_INDEX_HANDLING_CHARGES
        )
      }));
      setCheckoutState((prevState) => ({
        ...prevState,
        quantity: prevState.quantity + 1
      }));
    }
  };

  const decrementQuantity = () => {
    if (quantity > minQuantity) {
      setShowShippingRates(false);
      setCheckoutState((prevState) => ({
        ...prevState,
        quantity: prevState.quantity - 1,

        invoiceList: prevState.invoiceList.filter(
          (item) => item.sortIndex !== OrderConstants.INVOICE_INDEX_SHIPPING_CHARGES &&
            item.sortIndex !== OrderConstants.INVOICE_INDEX_HANDLING_CHARGES
        )
      }));
    }
  };

  const togglePickUpOption = () => {
    if (selectPickUp) {
      setCheckoutState((prevState) => ({
        ...prevState,
        selectPickUp: false
      }));
      if (shippingData.rates.length && shippingData.rates[selectedShippingOption]) {
        const newShippingName =
          shippingData.rates[selectedShippingOption].serviceName;
        const newShippingCost =
          shippingData.rates[selectedShippingOption].shipmentCost;
        const newItems: IInvoiceEntry[] = [{
          sortIndex: OrderConstants.INVOICE_INDEX_SHIPPING_CHARGES,
          name: "Shipping",
          key: newShippingName,
          value: newShippingCost,
          quantity: 1,
        }, {
          sortIndex: OrderConstants.INVOICE_INDEX_HANDLING_CHARGES,
          name: "Handling Charges",
          key: handlingChargeInfo.productInfo.id,
          value: handlingChargeInfo.priceData.unit_amount / 100,
          quantity: 1,
          productInfo: handlingChargeInfo.productInfo,
          priceData: handlingChargeInfo.priceData,
        }];
        setCheckoutState((prevState) => ({
          ...prevState,
          invoiceList: [...prevState.invoiceList, ...newItems]
        }));
      }
    } else {
      setCheckoutState((prevState) => ({
        ...prevState,
        selectPickUp: true,

        invoiceList: prevState.invoiceList.filter(
          (item) => item.sortIndex !== OrderConstants.INVOICE_INDEX_SHIPPING_CHARGES &&
            item.sortIndex !== OrderConstants.INVOICE_INDEX_HANDLING_CHARGES
        )
      }));
    }
  };

  const tileDisabled = ({
    date,
    view
  }: any) => {
    const isPastDate =
      new Date(date.toDateString()) <= new Date(new Date().toDateString());
    const isWeekend =
      (date.getDay() === 0 || date.getDay() === 6) && view === "month";
    return isPastDate || isWeekend;
  };

  const tileClassName = ({
    date,
    view
  }: any) => {
    if (view === "month") {
      if (
        date.getFullYear() === expeditedDate.getFullYear() &&
        date.getMonth() === expeditedDate.getMonth() &&
        date.getDate() === expeditedDate.getDate()
      ) {
        return "highlight";
      }
    }
    return null;
  };

  const handleClickDay = (date: Date) => {
    const { productInfo, priceData } = expeditedShippingInfo;
    if (
      date.getFullYear() === expeditedDate.getFullYear() &&
      date.getMonth() === expeditedDate.getMonth() &&
      date.getDate() === expeditedDate.getDate()
    ) {
      setCheckoutState((prevState) => ({
        ...prevState,
        expeditedShipping: true
      }));
      if (
        !invoiceList.find(
          (item) => item.sortIndex === OrderConstants.INVOICE_INDEX_EXPEDITED_SHIPPING
        )
      ) {
        const itemPrice = priceData.unit_amount / 100;
        const newItem = {
          sortIndex: OrderConstants.INVOICE_INDEX_EXPEDITED_SHIPPING,
          key: productInfo.id,
          name: "Rush Order",
          value: itemPrice,
          quantity: 1,
          productInfo,
          priceData,
        };
        setCheckoutState((prevState) => ({
          ...prevState,
          invoiceList: [...prevState.invoiceList, newItem]
        }));
      }
    } else {
      setCheckoutState((prevState) => ({
        ...prevState,
        expeditedShipping: false,

        invoiceList: prevState.invoiceList.filter(
          (item) => item.sortIndex !== OrderConstants.INVOICE_INDEX_EXPEDITED_SHIPPING
        )
      }));
    }
  };

  const handleShippingAddressSubmit = (e: React.FormEvent) => {
    if (e) e.preventDefault();
    setInvalidZipcode("");
    if (shippingAddress.country === "CA") {
      /** Check proper zip code format for Canada - format E.g. A1A 1A1 */
      const canadaZipcodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
      if (!canadaZipcodeRegex.test(shippingAddress.zipcode)) {
        setInvalidZipcode("Please enter a valid Zip/Postal Code");
        return;
      }
      setCheckoutState((prevState) => ({
        ...prevState,

        shippingAddress: {
          ...prevState.shippingAddress,
          zipcode: shippingAddress.zipcode.toUpperCase(),
        }
      }));
    } else {
      /** Check proper zip code format for United States - format E.g. 12345 */
      const usaZipcodeRegex = /^\d{5}$/;
      if (!usaZipcodeRegex.test(shippingAddress.zipcode)) {
        setInvalidZipcode("Please enter a valid Zip/Postal Code");
        return;
      }
    }
    setShowShippingRates(true);
    const shippingData = {
      toCity: shippingAddress.city,
      toState: shippingAddress.state,
      toCountry: shippingAddress.country,
      toPostalCode: shippingAddress.zipcode,
      quantity: quantity,
    };
    setCheckoutState((prevState) => ({
      ...prevState,

      shippingData: {
        packageWeight: {} as IShippingWeight,
        packageSize: {} as IShippingPackageSize,
        rates: [],
      },

      invoiceList: prevState.invoiceList.filter(
        (item) => item.sortIndex !== OrderConstants.INVOICE_INDEX_SHIPPING_CHARGES &&
          item.sortIndex !== OrderConstants.INVOICE_INDEX_HANDLING_CHARGES
      )
    }));
    setBusy(true);

    bwhsApi.getShippingRates(shippingData)
      .then((obj) => {
        setCheckoutState((prevState) => ({
          ...prevState,
          selectedShippingOption: 0,
          shippingData: obj
        }));
      })
      .catch((e) => {
        console.error(e);
        setCheckoutState((prevState) => ({
          ...prevState,
          sameAsBillingAddress: false,

          shippingAddress: {
            street1: "",
            street2: "",
            city: "",
            state: "AL",
            country: "US",
            zipcode: "",
          }
        }));
        setShowShippingRates(false);
        callErrorDialogueBox({
          title: "Error getting rates!",
          message:
            "Cannot get shipping rates for the given shipping address. Please use different address.",
          cancelBox: false,
          trueMessage: "Okay",
        });
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowShippingRates(false);
    setInvalidZipcode("");
    setCheckoutState((prevState) => ({
      ...prevState,
      sameAsBillingAddress: event.target.checked,

      invoiceList: prevState.invoiceList.filter(
        (item) => item.sortIndex !== OrderConstants.INVOICE_INDEX_SHIPPING_CHARGES &&
          item.sortIndex !== OrderConstants.INVOICE_INDEX_HANDLING_CHARGES
      )
    }));
    if (event.target.checked) {
      setCheckoutState((prevState) => ({
        ...prevState,
        shippingAddress: billingAddress
      }));
    } else {
      setCheckoutState((prevState) => ({
        ...prevState,

        shippingAddress: {
          street1: "",
          street2: "",
          city: "",
          state: "AL",
          country: "US",
          zipcode: "",
        }
      }));
    }
  };

  const handleShippingAddressChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (value.length > 5000) {
      /** Maximum limit of 5000 characters address*/
      return;
    }
    setShowShippingRates(false);
    setCheckoutState((prevState) => ({
      ...prevState,

      invoiceList: prevState.invoiceList.filter(
        (item) => item.sortIndex !== OrderConstants.INVOICE_INDEX_SHIPPING_CHARGES &&
          item.sortIndex !== OrderConstants.INVOICE_INDEX_HANDLING_CHARGES
      )
    }));
    setCheckoutState((prevState) => ({
      ...prevState,

      shippingAddress: {
        ...prevState.shippingAddress,
        [name]: value,
      }
    }));
  };

  const handleShippingMethod = (index: number) => {
    if (shippingData.rates.length && shippingData.rates[index]) {
      const newShippingName = shippingData.rates[index].serviceName;
      const newShippingCost = shippingData.rates[index].shipmentCost;

      setCheckoutState((prevState) => {
        let updatedInvoiceList = prevState.invoiceList;
        updatedInvoiceList = updatedInvoiceList.filter(
          (item) => item.sortIndex !== OrderConstants.INVOICE_INDEX_SHIPPING_CHARGES &&
            item.sortIndex !== OrderConstants.INVOICE_INDEX_HANDLING_CHARGES
        );
        const newItems = [{
          sortIndex: OrderConstants.INVOICE_INDEX_SHIPPING_CHARGES,
          name: "Shipping",
          key: newShippingName,
          value: newShippingCost,
          quantity: 1,
        }, {
          sortIndex: OrderConstants.INVOICE_INDEX_HANDLING_CHARGES,
          name: "Handling Charges",
          key: handlingChargeInfo.productInfo.id,
          value: handlingChargeInfo.priceData.unit_amount / 100,
          quantity: 1,
          productInfo: handlingChargeInfo.productInfo,
          priceData: handlingChargeInfo.priceData,
        }];
        updatedInvoiceList = [...updatedInvoiceList, ...newItems];
        return {
          ...prevState,
          selectedShippingOption: index,
          invoiceList: updatedInvoiceList,
        };
      });
    }
  };

  const handleDeliveryInfoSubmit = () => {
    if (state.PDFURL === undefined) {
      sessionStorage.removeItem("application-states");
      sessionStorage.removeItem("checkout-states");
      sessionStorage.removeItem("show-shipping-rates");
      navigate("/");
      return;
    }
    sessionStorage.setItem("show-shipping-rates", showShippingRates.toString());
    if (!selectPickUp && (!showShippingRates || !shippingData.rates.length)) {
      callErrorDialogueBox({
        title: "Alert",
        message: "Please select a shipping method for you address",
        cancelBox: false,
        trueMessage: "Okay",
      });
      return;
    }
    if (
      shippingAddressFormRef.current &&
      !shippingAddressFormRef.current.checkValidity()
    ) {
      return;
    }
    if (!orderID) return;
    const orderDetails: IOrderDetails = {
      quantity: quantity,
      pickup: selectPickUp,
      shippingDate: selectedDate.toDateString(),
      expeditedShipping: expeditedShipping,
      unitPrice: unitPrice,
      shippingMethod: shippingData.rates[selectedShippingOption],
      shippingData: {
        packageWeight: shippingData.packageWeight,
        packageSize: shippingData.packageSize,
      }
    };
    if (!selectPickUp) {
      orderDetails.shippingAddress = shippingAddress;
    }
    setBusy(true);
    try {
      bwhsApi.updateOrder(orderDetails, orderID);

      setCheckoutState((prevState) => ({
        ...prevState,
        currentIndex: 3
      }));

      setBusy(false);
    } catch (error: any) {
      console.error(error);
      callErrorDialogueBox({
        title: error.message + " !",
        message:
          "There's some interruption while connecting. Please try again after some time.",
        cancelBox: false,
        trueMessage: "Okay",
      });
      setBusy(false);
    }
  };

  useEffect(() => {
    handleShippingMethod(selectedShippingOption);
    // eslint-disable-next-line
  }, [shippingData]);

  useEffect(() => {
    const showShippingRatesValue = sessionStorage.getItem(
      "show-shipping-rates"
    );
    if (showShippingRatesValue) {
      setShowShippingRates(showShippingRatesValue === "true");
    }
    if (day === 4 || day === 5) {
      selectedDate.setDate(today.getDate() + 4);
    } else if (day === 6) {
      selectedDate.setDate(today.getDate() + 3);
    } else {
      selectedDate.setDate(today.getDate() + 2);
    }
    // eslint-disable-next-line
  }, []);

  // Set invoice total
  useEffect(() => {
    const sumOfValues = invoiceList.reduce((acc, obj) => acc + obj.value, 0);
    setCheckoutState((prevState) => ({
      ...prevState,
      invoiceTotal: sumOfValues
    }));
  }, [invoiceList, setCheckoutState]);

  // Change the unit prices
  useEffect(() => {
    let ignore = false;
    const priceParameters: IProductPriceRequest = {
      appProductCode: productSelection,
      quantity: quantity,
    }
    if (productSelection === DesignerProducts.RESUME_8X10) {
      if (state.colorPrintOption) {
        priceParameters.appProductSubCode = "color";
      } else {
        priceParameters.appProductSubCode = "bw";
      }
    }

    let abortController = new AbortController();
    bwhsApi.getProductPrice(priceParameters, abortController).then((obj) => {
      if (ignore) {
        return;
      }

      const priceData = obj.price;
      const pricePerUnit = priceData.unit_amount;
      const itemPrice = (quantity * pricePerUnit) / 100;
      setCheckoutState((prevState) => ({
        ...prevState,
        unitPrice: (pricePerUnit / 100).toFixed(2),
        totalQuantityPrice: itemPrice.toFixed(2),

        invoiceList: prevState.invoiceList.map((item) => item.sortIndex === OrderConstants.INVOICE_INDEX_PRODUCT
          ? { ...item, value: itemPrice, priceData, quantity }
          : item
        )
      }));
    })
      .catch((error) => {
        if (ignore) {
          return;
        }

        let errorMessage = "Could not process the request";
        if (error.response.status === 404) {
          const data = error.response.data;
          if (data.error) {
            errorMessage = data.error;
          }
        }
        callErrorDialogueBox({
          title: "Something went wrong!",
          message: errorMessage,
          cancelBox: false,
          trueMessage: "Okay",
        });
      });

    return () => {
      abortController.abort();
      ignore = true;
    };
    // eslint-disable-next-line
  }, [quantity, setCheckoutState]);

  // get expedited shipping rate
  useEffect(() => {
    let ignore = false;
    const abortController = new AbortController();
    bwhsApi.getProductPrice({
      appProductCode: "expedited",
      quantity: 1,
    }, abortController).then((obj) => {
      if (ignore) {
        return;
      }

      const productInfo = obj.product;
      const priceData = obj.price;
      setExpeditedShippingInfo({
        productInfo: productInfo,
        priceData: priceData,
      });
    })
      .catch((error) => {
        if (ignore) {
          return;
        }

        let errorMessage = "Could not process the request";
        if (error.response.status === 404) {
          const data = error.response.data;
          if (data.error) {
            errorMessage = data.error;
          }
        }
        callErrorDialogueBox({
          title: "Something went wrong!",
          message: errorMessage,
          cancelBox: false,
          trueMessage: "Okay",
        });
      });
    return () => {
      abortController.abort();
      ignore = true;
    };
    // eslint-disable-next-line
  }, []);

  // get handling charge
  useEffect(() => {
    let ignore = false;
    const abortController = new AbortController();
    bwhsApi.getProductPrice({
      appProductCode: "handling",
      quantity: 1,
    },
      abortController
    ).then((obj) => {
      if (ignore) {
        return;
      }

      const productInfo = obj.product;
      const priceData = obj.price;
      setHandlingChargeInfo({
        productInfo: productInfo,
        priceData: priceData,
      });
    })
      .catch((error) => {
        if (ignore) {
          return;
        }

        let errorMessage = "Could not process the request";
        if (error.response.status === 404) {
          const data = error.response.data;
          if (data.error) {
            errorMessage = data.error;
          }
        }
        callErrorDialogueBox({
          title: "Something went wrong!",
          message: errorMessage,
          cancelBox: false,
          trueMessage: "Okay",
        });
      });
    return () => {
      abortController.abort();
      ignore = true;
    };
    // eslint-disable-next-line
  }, []);

  return <>
    <div className="flex mt-5 mb-10 bg-white w-5/6 overflow-hidden rounded-lg shadow-lg">
      <div className="w-3/4 mx-auto px-10 pb-6 pt-3 border-r-2">
        <h2 className="text-2xl text-center my-4 font-medium uppercase">
          Shipping Details
        </h2>
        <hr />
        <div className="flex justify-between space-x-4">
          <div className="w-1/6">
            <h2 className="py-2 font-sans font-semibold text-lg mt-6 mb-2">
              Quantity
            </h2>
            <div className="flex items-center w-fit space-x-0 border border-black-200 rounded-md overflow-hidden">
              <button
                className={`bg-gray-200 duration-100 text-gray-700 w-8 h-8 pb-1 flex text-3xl items-center justify-center ${quantity === 1 ? "bg-gray-50 text-gray-300 cursor-auto" : ""
                  }`}
                onClick={decrementQuantity}
              >
                -
              </button>
              <input
                className="inline w-fit h-8 text-center"
                type="number"
                min={minQuantity}
                max={MAX_ORDER_QUANTITY}
                value={quantity}
                style={{ appearance: "textfield" }}
                onChange={(e) => {
                  const qty = parseInt(e.target.value);
                  if (
                    qty >= minQuantity &&
                    qty <= MAX_ORDER_QUANTITY
                  )
                    setCheckoutState((prevState) => ({
                      ...prevState,
                      quantity: qty
                    }));
                }}
              />
              <button
                className={`bg-gray-200 duration-100 text-gray-700 w-8 h-8 pb-1 flex text-3xl items-center justify-center ${quantity === MAX_ORDER_QUANTITY
                  ? "bg-gray-50 text-gray-300 cursor-auto"
                  : ""
                  } `}
                onClick={incrementQuantity}
              >
                +
              </button>
            </div>
            <div className="flex space-x-7 mt-4">
              <div className="flex flex-col text-wrap">
                <label>Unit Price</label>
                <label className="font-semibold">$ {unitPrice}</label>
              </div>
              <div className="flex flex-col text-wrap">
                <label>Total Price</label>
                <label className="font-semibold">
                  $ {totalQuantityPrice}
                </label>
              </div>
            </div>
          </div>
          <div className="w-2/6">
            <div className="flex flex-col mt-8">
              <div className="text-wrap text-sm font-sans font-semibold">
                Do you want to save on shipping and pick up your order for
                FREE at our location in Times Square?
              </div>
              <div className="">
                <button
                  onClick={togglePickUpOption}
                  className={`min-w-12 h-6 mt-3 flex items-center rounded-full p-1 cursor-pointer ${selectPickUp ? "bg-blue-500" : "bg-gray-300"
                    }`}
                >
                  <div
                    className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${selectPickUp ? "translate-x-6" : "translate-x-0"
                      }`}
                  ></div>
                </button>
              </div>
            </div>
            <div
              className={`bg-[#E2DBD0] mt-4 p-4 shadow-md w-full rounded-lg ${!selectPickUp ? "opacity-30" : ""
                }`}
            >
              The pickup location is: <br />
              <label className="italic font-bold text-wrap">
                Open Jar Studios 1601 Broadway, 11th floor New York, NY 10019
                (Enter on W. 48th)
              </label>
            </div>
          </div>
          <div className="w-3/6 p-5 pl-11">
            <div className="flex items-baseline space-x-8">
              <div className="text-lg font-sans font-semibold">
                {selectPickUp ? "Pickup" : "Shipping"} Date
              </div>
              <label className="text-sm text-gray-700">
                {selectedDate.toDateString()}
              </label>
            </div>
            <Calendar
              calendarType="gregory"
              onChange={(value) => {
                const newDate = value as Date;
                setCheckoutState((prevState) => ({
                  ...prevState,
                  selectedDate: newDate
                }));
              }}
              value={selectedDate}
              tileDisabled={tileDisabled}
              tileClassName={tileClassName}
              onClickDay={handleClickDay}
            />
            <label
              className={`text-green-700 font-medium mt-1 ${expeditedShipping ? "block" : "hidden"
                }`}
            >
              {"Rush Orders will incur an additional fee."}
            </label>
            <div className="font-sans text-sm font-medium text-wrap my-1">
              <div className="inline-flex items-center justify-center h-[17px] w-[17px] font-semibold font-sans border border-black-400 rounded-full text-black mr-1">
                i
              </div>
              Orders will be available after 5pm on the date you select.
            </div>
          </div>
        </div>

        {!selectPickUp && (
          <div className="bg-gray-100 mt-6 rounded-lg p-5">
            <div className="w-full bg-white px-10 pb-6 pt-3 rounded-lg shadow-lg">
              <h2 className="pb-2 font-semibold text-lg">Shipping address</h2>
              <input
                type="checkbox"
                id="Address Checkbox"
                checked={sameAsBillingAddress}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="Address Checkbox">
                &nbsp; Same as billing address
              </label>
              <form
                id="shippingAddressForm"
                ref={shippingAddressFormRef}
                className="space-y-4 mt-4"
                onSubmit={handleShippingAddressSubmit}
              >
                <fieldset
                  className="space-y-4"
                  disabled={sameAsBillingAddress}
                >
                  <div>
                    <label
                      htmlFor="address-1"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Address Line 1<label className="text-red-600">*</label>
                    </label>
                    <input
                      type="text"
                      id="address-1"
                      name="street1"
                      maxLength={5000}
                      autoComplete="shipping street-address address-line1"
                      required
                      value={shippingAddress.street1}
                      onChange={handleShippingAddressChange}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="address-2"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Address Line 2
                    </label>
                    <input
                      type="text"
                      id="address-2"
                      name="street2"
                      maxLength={5000}
                      autoComplete="shipping street-address address-line2"
                      value={shippingAddress.street2}
                      onChange={handleShippingAddressChange}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div className="flex space-x-5 justify-between">
                    <div className="w-full">
                      <label
                        htmlFor="address-city"
                        className="block text-sm font-medium text-gray-700"
                      >
                        City<label className="text-red-600">*</label>
                      </label>
                      <input
                        type="text"
                        id="address-city"
                        name="city"
                        maxLength={5000}
                        autoComplete="shipping address-level2"
                        required
                        value={shippingAddress.city}
                        onChange={handleShippingAddressChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="address-country"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Country<label className="text-red-600">*</label>
                      </label>
                      <select
                        id="address-country"
                        name="country"
                        autoComplete="shipping country-name"
                        required
                        value={shippingAddress.country}
                        onChange={(e) => {
                          handleShippingAddressChange(e);
                          setInvalidZipcode("");
                        }}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      >
                        <option value="US">United States</option>
                        <option value="CA">Canada</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex space-x-5 justify-between">
                    <div className="w-full">
                      <label
                        htmlFor="address-state"
                        className="block text-sm font-medium text-gray-700"
                      >
                        State/Province
                        <label className="text-red-600">*</label>
                      </label>
                      <select
                        id="address-state"
                        name="state"
                        autoComplete="shipping address-level1"
                        required
                        value={shippingAddress.state}
                        onChange={handleShippingAddressChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      >
                        {shippingAddress.country === "US"
                          ? USAStates
                          : CanadaStates}
                      </select>
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="address-zip"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Zip/Postal Code
                        <label className="text-red-600"> *</label>
                      </label>
                      <input
                        type="text"
                        id="address-zip"
                        name="zipcode"
                        autoComplete="shipping postal-code"
                        required
                        value={shippingAddress.zipcode}
                        onChange={(e) => {
                          handleShippingAddressChange(e);
                          setInvalidZipcode("");
                        }}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                      <div className="text-red-600 mt-1 text-sm">
                        {invalidZipcode}
                      </div>
                    </div>
                  </div>
                </fieldset>
                {!showShippingRates && (
                  <button
                    type="submit"
                    className="border px-8 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:scale-105 active:scale-100"
                  >
                    Set Shipping Address
                  </button>
                )}
              </form>
            </div>
            {showShippingRates && (
              <>
                <h2 className="text-2xl text-center mb-2 mt-14 font-medium">
                  Select a shipping method
                </h2>
                <div>
                  {shippingData.rates.length ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
                      {shippingData.rates.map(
                        (item, index) =>
                          item && (
                            <div
                              key={index}
                              onClick={() => handleShippingMethod(index)}
                              className={`${selectedShippingOption === index
                                ? "bg-green-400"
                                : "bg-white"
                                } p-4 rounded-lg text-wrap shadow-md hover:scale-105 duration-200 cursor-pointer hover:shadow-gray-400 active:scale-95`}
                            >
                              <p>{item.serviceName}</p>
                              <p className="">${item.shipmentCost}</p>
                            </div>
                          )
                      )}
                    </div>
                  ) : busy ? (
                    <div className="flex justify-center items-center">
                      <div className="loader"></div>
                    </div>) : (
                    <div className="text-center text-red-500">
                      No shipping methods available for the given address
                    </div>)
                  }
                </div>
              </>
            )}
          </div>
        )}
        <div className="flex justify-between mt-5">
          <button
            onClick={() => {
              if (state.PDFURL === undefined) {
                sessionStorage.removeItem("application-states");
                sessionStorage.removeItem("checkout-states");
                sessionStorage.removeItem("show-shipping-rates");
                navigate("/");
                return;
              }
              setCheckoutState((prevState) => ({
                ...prevState,
                currentIndex: 1
              }));
            }}
            className="border px-8 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:scale-105 active:scale-100"
          >
            Back
          </button>
          <button
            type="submit"
            onClick={handleDeliveryInfoSubmit}
            className="border px-8 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:scale-105 active:scale-100"
          >
            Next
          </button>
        </div>
      </div>

      <div className="w-1/4 bg-[#E2DBD0]">
        <h2 className="text-2xl text-center mt-4 font-medium uppercase">
          Invoice
        </h2>
        <div className="bg-gray-100 m-5 rounded-lg p-5 px-9">
          {invoiceList
            .sort((a, b) => a.sortIndex - b.sortIndex)
            .map((item, index) => (
              <div key={index} className="flex justify-between mb-3">
                <div>{item.name}</div>
                <div>$ {item.value.toFixed(2)}</div>
              </div>
            ))}
          <hr />
          <br />
          <div className="flex justify-between">
            <div>Total</div>
            <div>$ {invoiceTotal.toFixed(2)}</div>
          </div>
        </div>
        <div className="italic px-6 text-wrap text-red-500">
          Taxes will be shown on the payment page
        </div>
      </div>
    </div>
  </>;
};

export default DeliveryInfoForm;
