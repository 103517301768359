import React, { useEffect, useRef, useState } from "react";
import Canvas from "./inputComponents/canvas";
import zoomIn from "../../../assets/images/zoom-in.svg";
import zoomOut from "../../../assets/images/zoom-out.svg";
import TextEditor from "../../common/textEditor";
import handleTextEditorMouseDown from "../../../util/handleTextEditorMouseDown";
import { DesignerProducts } from "../../../services/constants";
import {
  BorderOptions,
  BorderTypeOptions,
  ProductOptions,
} from "../../../types/designer";
import ReactQuill from "react-quill";
import { IAppState } from "../../../types/states";

let zoomMouseDown = false;

export type CanvasSectionProps = {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  isPortrait: boolean;
  imageBase64File?: string;
  textEditorValue: string;
  textEditorPosition: { x: number; y: number };
  textEditorWidthPx: number;
  setState: React.Dispatch<React.SetStateAction<IAppState>>;
  redirect: string;
  quillRef: React.RefObject<ReactQuill>;
  imageEffectiveDPI: number;
  imagePositionForCanvas: { x: number; y: number };
  imageCanvasDimensions: { height: number; width: number };
  selectedBorderOption: BorderOptions;
  selectedBorderTypeOption: BorderTypeOptions;
  handleScrollZoom: (event: WheelEvent | null, zoomIn: boolean) => void;
  scalingPercentage: number;
  productSelection: ProductOptions;
  isSelectedFilePdf: boolean;
};

const CanvasSection = ({
  canvasRef,
  isPortrait,
  imageBase64File,
  textEditorValue,
  textEditorPosition,
  textEditorWidthPx,
  setState,
  redirect,
  quillRef,
  imageEffectiveDPI,
  imagePositionForCanvas,
  imageCanvasDimensions,
  selectedBorderOption,
  selectedBorderTypeOption,
  handleScrollZoom,
  scalingPercentage,
  productSelection,
  isSelectedFilePdf,
}: CanvasSectionProps) => {
  const textEditorStyle: React.CSSProperties = {
    position: "absolute",
    left: textEditorPosition.x + "px",
    top: textEditorPosition.y + "px",
  };

  const [positionError, setPositionError] = useState(false);
  const [toolbarVisibility, setToolbarVisibility] = useState(false);

  const enableCoverageChecks = productSelection !== "resume";
  const enableDpiChecks = !isSelectedFilePdf;

  useEffect(() => {
    const element = document.querySelector(
      ".ql-toolbar.ql-snow"
    ) as HTMLElement;
    if (element) {
      if (toolbarVisibility) element.style.visibility = "visible";
      else element.style.visibility = "hidden";
    }
  }, [toolbarVisibility]);

  useEffect(() => {
    const canvas = canvasRef.current!;
    const canvasHeight = canvas.height;
    const canvasWidth = canvas.width;
    const x = imagePositionForCanvas.x;
    const y = imagePositionForCanvas.y;
    const imageHeight = imageCanvasDimensions.height;
    const imageWidth = imageCanvasDimensions.width;
    if (
      imageBase64File &&
      ((selectedBorderOption === "noBorder" &&
        (x > 0 ||
          y > 0 ||
          canvasHeight - y - imageHeight > 0 ||
          canvasWidth - x - imageWidth > 0)) ||
        ((selectedBorderOption === "blackBorder" ||
          selectedBorderOption === "whiteBorder") &&
          ((selectedBorderTypeOption === "wide" &&
            ((isPortrait &&
              (x > 99 ||
                y > 45 ||
                canvasHeight - y - imageHeight > 81 ||
                canvasWidth - x - imageWidth > 99)) ||
              (!isPortrait &&
                (x > 45 ||
                  y > 81 ||
                  canvasHeight - y - imageHeight > 108 ||
                  canvasWidth - x - imageWidth > 45)))) ||
            (selectedBorderTypeOption === "standard" &&
              ((isPortrait &&
                (x > 45 ||
                  y > 45 ||
                  canvasHeight - y - imageHeight > 81 ||
                  canvasWidth - x - imageWidth > 45)) ||
                (!isPortrait &&
                  (x > 45 ||
                    y > 45 ||
                    canvasHeight - y - imageHeight > 81 ||
                    canvasWidth - x - imageWidth > 45)))))))
    ) {
      setPositionError(true);
    } else {
      setPositionError(false);
    }
  }, [
    imagePositionForCanvas,
    imageBase64File,
    imageCanvasDimensions,
    canvasRef,
    selectedBorderOption,
    selectedBorderTypeOption,
    isPortrait,
  ]);

  const [zoomRepeatIntervalId, setZoomRepeatIntervalId] = useState<
    NodeJS.Timeout | undefined
  >();
  const [zoomRepeatDelayTimeoutId, setZoomRepeatDelayTimeoutId] = useState<
    NodeJS.Timeout | undefined
  >();
  const handleScrollZoomRef = useRef(handleScrollZoom);

  useEffect(() => {
    handleScrollZoomRef.current = handleScrollZoom;
  }, [handleScrollZoom]);

  const startZoom = (zoomIn: boolean) => {
    clearInterval(zoomRepeatIntervalId);
    setZoomRepeatIntervalId(undefined);
    clearTimeout(zoomRepeatDelayTimeoutId);
    setZoomRepeatDelayTimeoutId(undefined);

    // Delay before triggering auto-repeat
    const zoomRepeatDelayTimeout = setTimeout(() => {
      clearInterval(zoomRepeatIntervalId);
      setZoomRepeatIntervalId(undefined);

      if (!zoomMouseDown) {
        return;
      }

      // Auto-repeat
      const id = setInterval(() => {
        handleScrollZoomRef.current(null, zoomIn);
      }, 25);
      setZoomRepeatIntervalId(id);
    }, 500);
    setZoomRepeatDelayTimeoutId(zoomRepeatDelayTimeout);

    handleScrollZoomRef.current(null, zoomIn);

    zoomMouseDown = true;
  };

  const stopZoom = () => {
    zoomMouseDown = false;

    clearTimeout(zoomRepeatDelayTimeoutId);
    setZoomRepeatDelayTimeoutId(undefined);

    clearInterval(zoomRepeatIntervalId);
    setZoomRepeatIntervalId(undefined);
  };

  return (
    <>
      {/* Upload Section */}
      <div className="w-full overflow-y-auto overflow-x-hidden max-h-[calc(100vh_0px)]">
        <div className="bg-beige-400 flex justify-center items-center w-full min-h-screen p-5">
          <div className=" flex flex-col justify-center items-center">
            {enableDpiChecks && (
              <label
                className={`text-sm rounded-md mb-[3px] text-white font-medium py-1 px-6 ${
                  imageBase64File && imageEffectiveDPI < 250
                    ? "visible"
                    : "invisible"
                } ${imageEffectiveDPI < 200 ? "bg-red-600" : "bg-orange-400"}`}
              >
                Print quality of this image is{" "}
                {imageEffectiveDPI < 200 ? "very" : ""} low:{" "}
                {imageEffectiveDPI.toFixed(2)} DPI
              </label>
            )}

            <div className="relative overflow-hidden">
              <Canvas canvasRef={canvasRef} isPortrait={isPortrait} />
              {imageBase64File &&
                (redirect === DesignerProducts.HEADSHOT_PRINTS ||
                  redirect === DesignerProducts.DIGITAL_HEADSHOT) && (
                  <div
                    style={textEditorStyle}
                    onMouseEnter={() => setToolbarVisibility(true)}
                    onMouseLeave={() => setToolbarVisibility(false)}
                    onMouseDown={(event) =>
                      handleTextEditorMouseDown(event, setState, canvasRef)
                    }
                  >
                    <TextEditor
                      textEditorValue={textEditorValue}
                      setState={setState}
                      isPortrait={isPortrait}
                      textEditorPosition={textEditorPosition}
                      textEditorWidthPx={textEditorWidthPx}
                      canvasRef={canvasRef}
                      quillRef={quillRef}
                      selectedBorderOption={selectedBorderOption}
                      selectedBorderTypeOption={selectedBorderTypeOption}
                    />
                  </div>
                )}
            </div>
            {enableCoverageChecks && (
              <label
                className={`text-sm rounded-md mt-[3px] text-white font-medium py-1 px-6 bg-red-600 ${
                  positionError ? "visible" : "invisible"
                }`}
              >
                Your image should cover the{" "}
                {selectedBorderOption === "noBorder" ? "canvas" : "border"} from
                all sides.
              </label>
            )}
          </div>
          {imageBase64File && (
            <div className="flex flex-col items-center space-y-3 min-w-[8%]">
              <button
                title="Zoom In"
                onMouseDown={() => startZoom(true)}
                onMouseUp={stopZoom}
                onMouseLeave={stopZoom}
                className="bg-white w-fit h-fit rounded-lg p-2 border-b-[3px] border-gray-400 active:border-0 pr-[9px] duration-200 cursor-pointer shadow-md hover:shadow-lg active:shadow-md active:scale-95 hover:scale-105"
              >
                <img
                  src={zoomIn}
                  className="h-6 aspect-square pointer-events-none"
                  alt="Zoom In"
                />
              </button>
              <div className="font-bold font-sans">
                {scalingPercentage.toFixed(2) + " %"}
              </div>
              <button
                title="Zoom Out"
                onMouseDown={() => startZoom(false)}
                onMouseUp={stopZoom}
                onMouseLeave={stopZoom}
                className="bg-white w-fit h-fit rounded-lg p-2 border-b-[3px] border-gray-400 active:border-0 pr-[9px] duration-200 cursor-pointer shadow-md hover:shadow-lg active:shadow-md active:scale-95 hover:scale-105"
              >
                <img
                  src={zoomOut}
                  className="h-6 aspect-square pointer-events-none"
                  alt="Zoom Out"
                />
              </button>
            </div>
          )}
        </div>
      </div>{" "}
      {/* End - Upload Section */}
    </>
  );
};

export default CanvasSection;
